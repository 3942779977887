import React from "react";
import ReactDOM from "react-dom";
import fingerprint from "../ER_Agents/imgs/Fingerprint.png";
import call from "../ER_Boyard/imgs/call.gif";
import calling from "../ER_Boyard/imgs/calling.gif";
import callaudio from "../ER_Boyard/audio/robin_4.mp3";
import alarm from "../ER_Agents/audio/alarm.mp3";
import styles from "../ER_Boyard/alarm.css";
import {
  Layout,
  Menu,
  Radio,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import { withTranslation } from "react-i18next";

class Alarm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      playing: false,
      message: false,
    };
    this.check = this.check.bind(this);
    this.play = this.play.bind(this);
  }
  check() {
    if (
      document.getElementById("1").checked &&
      !document.getElementById("2").checked &&
      document.getElementById("3").checked &&
      document.getElementById("4").checked &&
      !document.getElementById("5").checked &&
      document.getElementById("6").checked &&
      !document.getElementById("7").checked
    ) {
      window.location.href = "end";
    }
  }
  start(e) {
    var audio = document.getElementById("alarm");
    audio.play();
    document.getElementById("overlay").style.visibility = "hidden";
  }
  play() {
    if (!this.state.playing) {
      var audio = document.getElementById("call");
      this.setState({ playing: true });
      audio.play();
      setTimeout(() => {
        var audio = document.getElementById("call");
        audio.pause();
        this.setState({ message: true });
      }, 34000);
    }
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Boyard_Alarm">
        <Modal
          title={t("robin-phone")}
          visible={this.state.modal}
          width={570}
          footer={[]}
        >
          {this.state.playing && this.state.message && (
            <div>
              <p>
              {t("robin-step1")}
              </p>
              <p>
                {t("robin-step2")}
              </p>
              <p>
                {t("robin-step3")}
              </p>
              <Button onClick={() => this.setState({ modal: false })}>
                {t("robin-step4")}
              </Button>
            </div>
          )}
          {this.state.playing && !this.state.message && (
            <img
              src={calling}
              style={{ width: "70%", marginLeft: "15%" }}
              onClick={this.play}
            />
          )}
          {!this.state.playing && (
            <img
              src={call}
              style={{ width: "70%", marginLeft: "15%" }}
              onClick={this.play}
            />
          )}
        </Modal>

        <p id="time"></p>

        <div style={{ backgroundColor: "#888" }}>
          <p id="info">
            {t("robin-cam")} <span style={{ color: "green" }}>  {t("robin-online")}</span>
          </p>
          <span className="toggle">
            <input onClick={this.check} id="1" type="checkbox" />
            <label className="tlabel" data-off={t("robin-red")} data-on={t("robin-yellow")}></label>
          </span>

          <span className="toggle">
            <input onClick={this.check} id="2" type="checkbox" />
            <label data-off={t("robin-on")} data-on={t("robin-off")}></label>
          </span>

          <span className="toggle">
            <input onClick={this.check} id="3" type="checkbox" />
            <label id="b1" data-off="8" data-on="3"></label>
          </span>
          <span className="toggle">
            <input onClick={this.check} id="4" type="checkbox" />
            <label id="b2" data-off="&#9724;" data-on="&#x2605;"></label>
          </span>
          <br />
          <span className="toggle">
            <input onClick={this.check} id="5" type="checkbox" />
            <label id="b3" data-off={t("robin-stop")} data-on={t("robin-start")}></label>
          </span>
          <span className="toggle">
            <input onClick={this.check} id="6" type="checkbox" />
            <label id="b4" data-off="11" data-on="7"></label>
          </span>
          <span className="toggle">
            <input onClick={this.check} id="7" type="checkbox" />
            <label data-off="B" data-on="K"></label>
          </span>
        </div>
        <audio src={callaudio} id="call"></audio>
      </div>
    );
  }
}

export default withTranslation(['games'])(Alarm);
