import React from "react";
import ReactDOM from "react-dom";
import styles from "../styles/main.css";
import overlay from "../imgs/logo.png";
import { Button } from "antd";

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.activate = this.activate.bind(this);
  }
  activate() {
    localStorage.setItem("festiviti_start_time", new Date().getTime());
    localStorage.setItem("festiviti_extra_time", 0);
    localStorage.setItem("festiviti_punish_time", 0);
    window.location.href = this.props.next;
  }
  render() {
    return (
      <div
        id="window"
        className="start_screen"
        style={{ backgroundImage: "url(" + this.props.background + ")" }}
      >
        <div className="centerbuttons">
          <p>
            Het is zover!
            <br /> Klaar voor een spannend avontuur?
          </p>

          {this.props.settings && (
            <a
              className="linkbutton"
              style={{ marginBottom: 10 }}
              onClick={() => (window.location.href = "startsettings")}
            >
              Instellingen
            </a>
          )}
          <a className="linkbutton" onClick={this.activate}>
            {this.props.escape ? "Start Escape Room" : "Start Spel"}
          </a>
        </div>
        <img className="overlay" src={overlay} />
      </div>
    );
  }
}

export default Start;
