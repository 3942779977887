import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Agents/buttons_box.jsx";
import blue_audio from "../ER_Agents/audio/snake.mp3";
import red_audio from "../ER_Agents/audio/dog.mp3";
import yellow_audio from "../ER_Agents/audio/goat.mp3";
import green_audio from "../ER_Agents/audio/bird.mp3";
import styles from "../ER_Agents/buttons.css";

class Buttons extends React.Component {
  componentDidMount() {
    javascript("morse");
  }
  render() {
    return (
      <div className="Agents_Buttons">
        <audio src={blue_audio} id="blue_audio"></audio>
        <audio src={red_audio} id="red_audio"></audio>
        <audio src={green_audio} id="green_audio"></audio>
        <audio src={yellow_audio} id="yellow_audio"></audio>
        <p id="time"></p>
        <div id="centered">
          <span className="btn-blue"></span>
          <span className="btn-red"></span>
          <span className="btn-green"></span>
          <span className="btn-yellow"></span>
        </div>
        <p className="bottom" id="text1"></p>
      </div>
    );
  }
}

export default Buttons;
