import React from "react";
import ReactDOM from "react-dom";
import left from "../ER_Labo/imgs/left.png";
import right from "../ER_Labo/imgs/right.png";
import experiment from "../ER_Labo/imgs/experiment.png";
import back from "../ER_Labo/imgs/back.png";
import style from "../ER_Labo/menu.css";
import { withTranslation } from "react-i18next";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secretVisible: false,
    };
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Labo_Menu">
        {this.state.secretVisible ? (
          <div>
            {" "}
            <img src={experiment} id="image" />
            <img
              src={back}
              id="back"
              onClick={() => this.setState({ secretVisible: false })}
            />
            <div id="timecontainer2">
              <p id="time2"></p>
            </div>
          </div>
        ) : (
          <div>
            <div
              id="left"
              onClick={() => this.setState({ secretVisible: true })}
            >
              <img class="icon" src={left} />
              <p class="title">{t("labo-docs")}</p>
            </div>
            <div id="right" onClick={() => (window.location.href = "recept")}>
              <img class="icon" src={right} />
              <p class="title">{t("labo-send")}</p>
            </div>
            <div id="timecontainer">
              <p id="time"></p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(['games'])(Menu);
