function startTime() {}
function getTime() {
  var time = new Date(
    parseInt(localStorage.getItem("festiviti_start_time"))
  ).getTime();
  var extra_minutes = parseInt(localStorage.getItem("festiviti_extra_time"));
  var punish_time = parseInt(localStorage.getItem("festiviti_punish_time"));
  var now = new Date().getTime();
  var minutes =
    Math.floor(((now - time) % (1000 * 60 * 60)) / (1000 * 60)) +
    extra_minutes +
    punish_time;
  var seconds = Math.floor(((now - time) % (1000 * 60)) / 1000);
  return [minutes, seconds];
}

function initTime() {
  var time = new Date(
    parseInt(localStorage.getItem("festiviti_start_time"))
  ).getTime();
  var extra_min = parseInt(localStorage.getItem("festiviti_extra_time"));
  var punish_min = parseInt(localStorage.getItem("festiviti_punish_time"));
  var interval;
  document.getElementById("id");
  interval = setInterval(function () {
    var now = new Date().getTime();
    var minutes =
      Math.floor(((time - now) % (1000 * 60 * 60)) / (1000 * 60)) +
      60 +
      extra_min +
      punish_min;
    var seconds = Math.floor(((time - now) % (1000 * 60)) / 1000) + 60;
    var time_element = document.getElementById("time");
    if (time_element)
      time_element.innerHTML =
        +("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
  }, 1000);
  return interval;
}

export default initTime;
export { initTime, getTime };
