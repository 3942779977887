import overlay from "../imgs/logo.png";
import { useParams } from "react-router";
import React, { useState } from 'react';
import {Modal, Button} from "antd";
import { withTranslation, useTranslation } from "react-i18next";

function Download(props) {
const buttonstyle= { marginBottom: 15, background: "#F0730D", borderColor: "#F0730D" };
const { t } = useTranslation();

   return (<Modal
          title={t('download-header')}
          visible={props.visible}
          onCancel={() => props.hide()}
          footer={[]}
        >
        <div style={{textAlign:"center"}}>
        <Button
            type="primary"
            target="_blank"
            href={"/downloads/"+props.game.sku+"/Handleiding.pdf"}
            style={buttonstyle}
            download
          >{t("download-manual")}
          </Button><br/>
          { props.game.info.type =="BOX" &&
          <div><p>Druk zelf nog extra reserve documenten af:</p>
          <Button
            type="primary"
            target="_blank"
            href={"/downloads/"+props.game.sku+"/Box_Prints.pdf"}
            style={buttonstyle}
            download
          >{t("download-extra")}
          </Button></div>}
           {props.game.sku!=="FB_ACT02" &&  props.game.info.type !="BOX" &&
          <Button
            type="primary"
            target="_blank"
            href={"/downloads/"+props.game.sku+"/Puzzels.pdf"}
            style={buttonstyle}
            download
          >{t("download-puzzles")}
          </Button>}<br/>
          {props.game.sku=="FB_ER01" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ER01/Brief_enkelvoud.pdf"
            style={buttonstyle}
            download
          >{t("download-letter-1")}
          </Button>}<br/>
          {props.game.sku=="FB_ER01" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ER01/Brief_meervoud.pdf"
            style={buttonstyle}
            download
          >{t("download-letter-2")}
          </Button>}
           {props.game.sku=="FB_ACT02" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ACT02/Brief.pdf"
            style={buttonstyle}
            download
          >{t("download-letter")}
          </Button>}<br/>
          {props.game.sku=="FB_ACT02" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ACT02/Doolhof.pdf"
            style={buttonstyle}
            download
          >{t("download-maze")}
          </Button>}<br/>
           {props.game.sku=="FB_ACT02" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ACT02/Embleem.pdf"
            style={buttonstyle}
            download
          >{t("download-emblem")}
          </Button>}<br/>
           {props.game.sku=="FB_ACT02" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ACT02/Wachtwoorden.pdf"
            style={buttonstyle}
            download
          >{t("download-passwords")}
          </Button>}<br/>
           {props.game.sku=="FB_ACT02" &&
          <Button
            type="primary"
            target="_blank"
            href="/downloads/FB_ACT02/Memory.pdf"
            style={buttonstyle}
            download
          >{t("download-memory")}
          </Button>}<br/>
          </div>

        </Modal>);
   }


export default withTranslation()(Download);
