import React from "react";
import ReactDOM from "react-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/panel.css";
import service from "../FB_Hero/imgs/secret_service.jpeg";
import car from "../FB_Hero/imgs/car.gif";
import call from "../FB_Hero/imgs/call.png";
import callgif from "../FB_Hero/imgs/call.gif";
import sent from "../FB_Hero/imgs/sent.png";
import police_nl from "../FB_Hero/audio/politie.mp3";

export class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: {
        r1: true,
        r2: true,
        r3: true,
        r4: true,
        r5: true,
        r6: true,
      },
      location: {
        lat: -25,
        lng: 131,
      },
    };
    this.move_location = this.move_location.bind(this);
  }
  componentDidMount() {
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(info);
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      { method: "GET", headers: { Authorization: "Bearer " + user.token } }
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ loaded: result });
        console.log(result);
      });
  }
  move_location() {
    const id = document.getElementById("code").value;
    if (id != "B69A") {
      document.getElementById("location_text").innerHTML = "Foutieve code";
      document.getElementById("location_text").style.color = "red";
      return false;
    }
    document.getElementById("location_text").innerHTML = "Locatie gevonden.";
    document.getElementById("location_text").style.color = "black";
    this.setState({ location: this.state.loaded });
  }
  call() {
    var audio = document.getElementById("audio");
    document.getElementById("telephone").src = callgif;
    audio.play();
  }
  quitcall() {
    document.getElementById("call").style.display = "none";
    document.getElementById("telephone").src = call;
    var audio = document.getElementById("audio");
    audio.pause();
    audio.currentTime = 0;
  }
  fotos() {
    document.getElementById("fotos").style.display = "block";
    var element = document.getElementById("foto_text");
    setTimeout(function () {
      element.innerHTML = "Foto's verzamelen van vluchtroute...";
    }, 3000);
    setTimeout(function () {
      element.innerHTML = "Foto's afdrukken en opsturen...";
    }, 7000);
    setTimeout(function () {
      element.innerHTML =
        "De foto's zijn afgedrukt en verstuurd. Ze zouden zich moeten bevinden in de postbus van jullie hoofdkwartier.";
      document.getElementById("loading_foto").src = sent;
      document.getElementById("backlink").style.display = "block";
    }, 10000);
  }
  render() {
    return (
      <div className="Hero_Panel" style={{ position: "absolute" }}>
        <audio id="audio" src={police_nl}></audio>
        <form style={{ marginTop: "10%" }}>
          <input
            className="knop"
            type="button"
            value="Vluchtroute bekijken"
            onClick={() =>
              (document.getElementById("route").style.display = "block")
            }
          />
          <input
            className="knop"
            type="button"
            value="Politie bellen"
            onClick={() =>
              (document.getElementById("call").style.display = "block")
            }
          />
          <input
            className="knop"
            type="button"
            value="Locatie traceren"
            onClick={() =>
              (document.getElementById("form").style.display = "block")
            }
          />
        </form>

        <div id="form" className="modal">
          <form className="modal-content animate">
            <div className="imgcontainer" style={{ height: "100%" }}>
              <span
                onClick={() =>
                  (document.getElementById("form").style.display = "none")
                }
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
              <Map
                id="map"
                google={this.props.google}
                zoom={13}
                style={{ width: "70%", height: 300, marginLeft: "15%" }}
                defaultCenter={this.state.location}
                center={this.state.location}
              >
                <Marker position={this.state.location} text=""></Marker>
              </Map>
            </div>

            <div class="container" style={{ marginTop: "300px" }}>
              <p id="location_text">
                Voer een locatie code in om deze te ontcijferen.
              </p>
              <input
                type="text"
                id="code"
                placeholder="Locatie Code"
                name="code"
                required
              />
              <button type="button" onClick={this.move_location}>
                Vind Locatie
              </button>
            </div>
          </form>
        </div>

        <div id="route" className="modal">
          <form className="modal-content animate" style={{ width: "300px" }}>
            <div className="imgcontainer">
              <span
                onClick={() =>
                  (document.getElementById("route").style.display = "none")
                }
                className="close"
                title="Close Modal"
              >
                &times;
              </span>
            </div>
            <div className="container">
              <div className="centerbuttons">
                <label className="labelcontainer">
                  Route A1
                  <input
                    type="radio"
                    value={this.state.route.r1}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="labelcontainer">
                  Route B2
                  <input
                    type="radio"
                    value={this.state.route.r2}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="labelcontainer">
                  Route C3
                  <input
                    type="radio"
                    value={this.state.route.r3}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="labelcontainer">
                  Route D4
                  <input
                    type="radio"
                    value={this.state.route.r4}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="labelcontainer">
                  Route E5
                  <input
                    type="radio"
                    value={this.state.route.r5}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
                <label class="labelcontainer">
                  Route F6
                  <input
                    type="radio"
                    value={this.state.route.r6}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <button type="button" onClick={this.fotos}>
                Camerabeelden Opvragen
              </button>
            </div>
          </form>
        </div>

        <div id="fotos" class="modal" style={{ paddingTop: "10%" }}>
          <form
            class="modal-content animate"
            style={{ width: "500px", height: "300px" }}
          >
            <div class="imgcontainer">
              <img src={car} alt="Avatar" id="loading_foto" class="avatar" />
            </div>
            <p id="foto_text">Downloaden van de beelden...</p>
            <p
              style={{ textAlign: "center", display: "none", fontSize: "13px" }}
              id="backlink"
            >
              <a
                onClick={() => {
                  document.getElementById("fotos").style.display = "none";
                  document.getElementById("route").style.display = "none";
                }}
              >
                TERUG
              </a>
            </p>
          </form>
        </div>

        <div id="call" class="modal" style={{ paddingTop: "10%" }}>
          <form
            class="modal-content animate"
            style={{ width: "400px", height: "300px" }}
          >
            <div class="imgcontainer">
              <span class="close" title="Close Modal" onClick={this.quitcall}>
                &times;
              </span>
              <img
                src={call}
                alt="Avatar"
                id="telephone"
                className="avatar"
                onClick={this.call}
              />
              <p style={{ fontSize: "13px" }}>
                Contacteer de politie wanneer jullie de locatie van een auto
                willen achterhalen.
                <br /> Controleer of het geluid van de computer aanstaat.
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API,
})(Panel);
