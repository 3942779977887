import styles from "../styles/main.css";
import React, { Component, ScrollView, useState } from "react";
import { withTranslation } from "react-i18next";

class FAQ extends Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    var acc = document.getElementsByClassName("accordion");
    var panel = document.getElementsByClassName("panel");

    for (var i = 0; i < acc.length; i++) {
      acc[i].onclick = function () {
        var setClasses = !this.classList.contains("active");
        for (var i = 0; i < acc.length; i++) {
          acc[i].classList["remove"]("active");
        }
        for (var i = 0; i < panel.length; i++) {
          panel[i].classList["remove"]("show");
        }
        if (setClasses) {
          this.classList.toggle("active");
          this.nextElementSibling.classList.toggle("show");
        }
      };
    }

    function setClass(els, className, fnName) {
      for (var i = 0; i < els.length; i++) {
        els[i].classList[fnName](className);
      }
    }
  }
  render() {
    const {t}= this.props;
    return (
      <div
        style={{ width: 700, display: "block", margin: "auto", paddingTop: 70 }}
      >
        <p className="accordion">
        {t("faq-q1")}
        </p>
        <div className="panel">
          {t("faq-a1")}
        </div>
        <p className="accordion">
          {t("faq-q2")}
        </p>
        <div className="panel">
          {t("faq-a2")}
        </div>
        <p className="accordion">
          {t("faq-q3")}
        </p>
        <div className="panel">
          {t("faq-a3")}
        </div>
        <p className="accordion">
          {t("faq-q4")}
        </p>
        <div className="panel">
          {t("faq-a4")}
        </div>
        <p className="accordion">
        {t("faq-q5")}
        </p>
        <div className="panel">
          {t("faq-a5")}
        </div>
      </div>
    );
  }
}
export default withTranslation()(FAQ);
