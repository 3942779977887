import $ from "jquery";

function javascript(next_code) {
  var buttons = "GGGG";
  function check() {
    console.log(buttons);
    if (buttons == "rygbrg") {
      window.location.href = next_code;
    }
  }

  $(".btn-blue").click(function () {
    var audio = document.getElementById("blue_audio");
    audio.play();
    buttons = (buttons + "b").slice(-6);
    check();
  });
  $(".btn-yellow").click(function () {
    var audio = document.getElementById("yellow_audio");
    audio.play();
    buttons = (buttons + "y").slice(-6);
    check();
  });
  $(".btn-green").click(function () {
    var audio = document.getElementById("green_audio");
    audio.play();
    buttons = (buttons + "g").slice(-6);
    check();
  });
  $(".btn-red").click(function () {
    var audio = document.getElementById("red_audio");
    audio.play();
    buttons = (buttons + "r").slice(-6);
    check();
  });
}

export default javascript;
