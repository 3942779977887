import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/chat.css";
import loading from "../FB_Hero/imgs/loading2.gif";
import p1 from "../FB_Hero/imgs/profile1.png";
import p2 from "../FB_Hero/imgs/profile2.png";
import p3 from "../FB_Hero/imgs/profile3.png";
import p4 from "../FB_Hero/imgs/profile4.png";
import p5 from "../FB_Hero/imgs/profile5.png";
import cross from "../FB_Hero/imgs/cross.png";

const pictures = {
  cleaner: p1,
  manager: p2,
  gaurd: p3,
  taster: p4,
  driver: p5,
  driver2: p5,
};

class Chat extends React.Component {
  constructor(props) {
    super(props);
    const search = new URLSearchParams(this.props.location.search);
    const { t } = this.props;
    this.t = t;
    this.person = search.get("p");
    this.state = {
      loading: true,
      i: 1,
      answer: t("FB_SH01_" + this.person + "_a_1"),
    };

    this.typeWriter = this.typeWriter.bind(this);
    this.next = this.next.bind(this);
  }
  typeWriter(i, text) {
    if (i < text.length) {
      document.getElementById("chatbox").innerHTML += text.charAt(i);
      setTimeout(() => this.typeWriter(i + 1, text), 50);
    }
    if (i == text.length && document.getElementById("b1").innerHTML != "") {
      document.getElementById("buttons").style.display = "block";
    }
  }
  next() {
    document.getElementById("buttons").style.display = "none";
    document.getElementById("chatbox").innerHTML = "";
    this.typeWriter(
      0,
      this.t("FB_SH01_" + this.person + "_q_" + (this.state.i + 1).toString())
    );
    this.setState({
      i: this.state.i + 1,
      answer: this.t(
        "FB_SH01_" + this.person + "_a_" + (this.state.i + 1).toString()
      ),
    });
  }
  componentDidMount() {
    setTimeout(
      () => {
        this.setState({ loading: false });
        this.typeWriter(0, this.t("FB_SH01_" + this.person + "_q_1"));
      },
      3000,
      this
    );
  }

  render() {
    return (
      <div className="Hero_Chat" style={{ width: "100%", height: "100%" }}>
        <div
          id="loading"
          className="center"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <img className="profilepicloader" src={pictures[this.person]} />
          <img className="loader" src={loading} />
        </div>

        <a href="witness">
          <img className="cross" src={cross} />
        </a>
        <div
          className="center"
          id="chatting"
          style={{ display: this.state.loading ? "none" : "block" }}
        >
          <img className="profilepic" src={pictures[this.person]} />
          <p className="name">{this.t("FB_SH01_" + this.person)}</p>
          <p id="chatbox"></p>
          <div id="buttons">
            <button id="b1" className="button" onClick={this.next}>
              {this.state.answer}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["games"])(withRouter(Chat));
