import React from "react";
import ReactDOM from "react-dom";
//import ReactSlider from 'react-slider';
import YouTube from "react-youtube";
import styles from "../ER_Astro/movie.css";
import { Slider, Row, Col } from "antd";

class Movie extends React.Component {
  stateChange(event) {
    if (event.data == 0) window.location.href = "access";
  }
  render() {
    const opts = {
      height: "700",
      width: "100%",
      playerVars: {
        rel: 0,
        showinfo: 0,
        autoplay: 1,
      },
    };
    return (
      <div id="window" style={{ height: "100%" }} className="Astronaut_Movie">
        <div className="videoWrapper">
          <YouTube
            className="videowrapper"
            videoId="vumHfR3xYww"
            opts={opts}
            onStateChange={this.stateChange}
          />
        </div>
      </div>
    );
  }
}

export default Movie;
