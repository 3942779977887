import React, { Component } from "react";
import key from "../imgs/key.svg";
import mail from "../imgs/mail.svg";
import logo from "../imgs/logo.png";
import background from "../imgs/background.jpg";
import styles from "../styles/login.css";
import { withTranslation } from "react-i18next";
import { spinner, remove_spinner, done } from "../login_animation.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordernumber: "",
      email: "",
      error_message: "",
      show_error: false,
    };
  }
  login1(e) {
    spinner(e);
  }
  login2(e) {
    spinner(e);
  }
  handleChange(event, key) {
    if (key == "ordernumber")
      this.setState({ ordernumber: event.target.value.toUpperCase() });
    if (key == "email") this.setState({ email: event.target.value });
  }
  handleClick(e) {
    if (this.state.ordernumber != "" && this.state.email != "") {
      spinner(e);
      fetch(process.env.REACT_APP_BACKEND + "/api/login", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " + btoa(this.state.ordernumber + ":" + this.state.email),
        },
      })
        .then((response) => response.json())
        .then((user) => {
          localStorage.setItem("user", JSON.stringify(user));
          done(e);
        })
        .catch((error) => {
          this.setState({ error_message: "Je inloggegevens zijn foutief." });
          remove_spinner();
          this.setState({ show_error: true });
        });
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className="cont"
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        <div className="demo">
          <div className="login">
            <div className="login__check">
              <img className="logo" src={logo}></img>
            </div>
            <div className="login__form">
              <p
                id="error_message"
                style={{
                  visibility: this.state.show_error ? "visible" : "invisible",
                }}
              >
                {this.state.error_message}
              </p>
              <div className="login__row">
                <object type="image/svg+xml" data={mail} height="20"></object>
                <input
                  type="text"
                  id="number"
                  className="login__input"
                  placeholder={t("group_name")}
                  value={this.state.ordernumber}
                  onChange={(e) => {
                    this.handleChange(e, "ordernumber");
                  }}
                />
              </div>

              <div className="login__row">
                <object type="image/svg+xml" data={key} height="20"></object>
                <input
                  type="text"
                  id="email"
                  className="login__input"
                  placeholder={t("group_password")}
                  value={this.state.email}
                  onChange={(e) => {
                    this.handleChange(e, "email");
                  }}
                />
              </div>
              <button
                type="button"
                className="login__submit"
                onClick={(event) => this.handleClick(event)}
              >
                {t("start")}
              </button>
              <p className="login__signup">
                {t("group-info")}{" "}
                <a href="http://www.festiviti.eu">{t("more-info")}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
