import React from "react";
import ReactDOM from "react-dom";
import ReactSlider from "react-slider";
import styles from "../ER_Astro/doors.css";
import { Slider, Row, Col } from "antd";
import { withTranslation } from "react-i18next";

class Doors extends React.Component {
  constructor(props) {
    super(props);}
  open_door() {
    const {t}=this.props;
    document.getElementById("window").style.backgroundColor = "green";
    document.getElementById("lock1").style.bottom = "200px";
    document.getElementById("lock2").style.bottom = "300px";
    document.getElementById("lock2").style.backgroundColor = "green";
    document.getElementById("inside").style.backgroundColor = "green";
    document.getElementById("title").innerHTML = t("astro-open");
    setTimeout(function () {
      window.location.href = "end";
    }, 2000);
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" style={{ height: "100%" }} className="Astronaut_Doors">
        <div class="wrapper">
          <div class="base">
            <div class="base-bottom"></div>
            <div class="lock-inside-top"></div>
            <div class="lock-inside-bottom"></div>
          </div>

          <div class="lock-cirlce" id="lock1">
            <div class="lock-circle-inside" id="inside"></div>
          </div>

          <div class="lock-box" id="lock2"></div>
        </div>
        <h1 id="title">
          {t('astro-closed-1')}<span onClick={() => this.open_door()}>R</span>{t('astro-closed-2')} {t('astro-closed-3')}
        </h1>
        <h2>
          {t("astro-level")} <br />
          <span id="time">00:00</span>
        </h2>
      </div>
    );
  }
}

export default withTranslation(['games'])(Doors);
