import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Astro/access.jsx";
import styles from "../ER_Astro/access.css";
import space1 from "../ER_Astro/imgs/spacesticker1.png";
import space2 from "../ER_Astro/imgs/spacesticker1.png";
import { withTranslation } from "react-i18next";

class Access extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    const {t}=this.props;
    javascript("phone",t("astro-restart"));
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Astronaut">
        <p>
          {t("astro-alarm")} <span id="time">59:00</span>
        </p>
        <div className="myCenter">
          <div id="lock" data-dials="3">
            <img class="sticker" src={space1} />
            <img class="sticker2" src={space2} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(Access);
