import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import initTime from "../time.js";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import End from "../../games/end.js";
import Access from "../ER_Boyard/access.js";
import Helicopter from "../ER_Boyard/helicopter.js";
import DoorCode from "../ER_Boyard/doorcode.js";
import Alarm from "../ER_Boyard/alarm.js";
import Rooms from "../ER_Boyard/rooms.js";

class ER_Boyard extends React.Component {
  componentDidMount() {
    document.title = "Festiviti Escape Room";
    initTime();
  }
  render() {
    if (this.props.match.params.phase == "play")
      return <Start background={backgroundIMG} escape={true} next="login" />;
    if (this.props.match.params.phase == "test")
      return <Test background={backgroundIMG} escape={true} next="login" />;
    if (this.props.match.params.phase == "end") return <End />;
    if (this.props.match.params.phase == "login") return <Access />;
    if (this.props.match.params.phase == "helicopter") return <Helicopter />;
    if (this.props.match.params.phase == "door") return <DoorCode />;
    if (this.props.match.params.phase == "alarm") return <Alarm />;
    if (this.props.match.params.phase == "rooms") return <Rooms />;
    else return <Redirect to="/" />;
  }
}

export default withRouter(ER_Boyard);
