import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import initTime from "../time.js";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import Access from "../FB_Hero/access.js";
import Welcome from "../FB_Hero/welcome.js";
import Chat from "../FB_Hero/chat.js";
import Suspects from "../FB_Hero/suspects.js";
import Panel from "../FB_Hero/panel.js";
import Settings from "../FB_Hero/settings.js";
import WitnessOverview from "../FB_Hero/witness_overview.js";

class FB_Hero extends React.Component {
  componentDidMount() {
    document.title = "Festiviti Escape Room";
    initTime();
  }
  render() {
    if (this.props.match.params.phase == "play")
      return (
        <Start
          background={backgroundIMG}
          escape={false}
          settings={true}
          next="access"
        />
      );
    if (this.props.match.params.phase == "test")
      return (
        <Test
          background={backgroundIMG}
          escape={false}
          settings={true}
          next="access"
        />
      );
    if (this.props.match.params.phase == "access")
      return <Access next="welcome" />;
    if (this.props.match.params.phase == "welcome")
      return <Welcome next="access" />;
    if (this.props.match.params.phase == "chat") return <Chat next="access" />;
    if (this.props.match.params.phase == "witness")
      return <WitnessOverview next="welcome" />;
    if (this.props.match.params.phase == "suspects")
      return <Suspects next="welcome" />;
    if (this.props.match.params.phase == "panel") return <Panel next="" />;
    if (this.props.match.params.phase == "testsettings")
      return <Settings back="test" />;
    if (this.props.match.params.phase == "startsettings")
      return <Settings back="play" />;
    else return <Redirect to="/" />;
  }
}

export default withRouter(FB_Hero);
