import React, { useState, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";
import "../styles/main.css";
import ER_Agents from "../games/ER_Agents/game.js";
import BOX_Agents from "../games/ER_Agents/game_box.js";
import ER_Astro from "../games/ER_Astro/game.js";
import ER_Boyard from "../games/ER_Boyard/game.js";
import ER_Labo from "../games/ER_Labo/game.js";
import FB_Hero from "../games/FB_Hero/game_FB.js";
import FG_Hero from "../games/FB_Hero/game_FG.js";
import MINI_HelpBunny from "../games/MINI_HelpBunny/game.js";
import DetectiveGame from "../games/Detective/game.js";
import CHRSPECIAL from "../games/CHRSPECIAL/game.js";
import { withTranslation, useTranslation } from "react-i18next";

function authorize(result) {
  var info = JSON.parse(localStorage.getItem("game_info"));
  if (info["testmode"] && !result["test"]) {
    //window.location.href = "/";
  }
  if (!info["testmode"] && !result["play"]) {
    //window.location.href = "/";
  }
}
var top = true;
function move() {
  console.log(top);
  if (top) {
    document.getElementById("testmode").style.bottom = 0;
    document.getElementById("testmode").style.right = 0;
    document.getElementById("testmode").style.top = "";
    document.getElementById("testmode").style.left = "";
    top = false;
  } else {
    document.getElementById("testmode").style.top = 0;
    document.getElementById("testmode").style.left = 0;
    document.getElementById("testmode").style.bottom = "";
    document.getElementById("testmode").style.right = "";
    top = true;
  }
}
function Game() {
  let { sku, phase } = useParams();
  const { t, i18n } = useTranslation(['games']);
  var user = JSON.parse(localStorage.getItem("user"));
  var info = JSON.parse(localStorage.getItem("game_info"));
  fetch("/api/play?game=" + info["game"] + "&order=" + info["order"], {
    method: "GET",
    headers: { Authorization: "Bearer " + user.token },
  })
    .then((response) => response.json())
    .then((result) => authorize(result));
  if (sku == "BOX_ER01")
    return (
      <Suspense fallback="loading">
        {phase != "test" && phase != "end" && info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <BOX_Agents phase={phase} />
      </Suspense>
    );
  if (sku == "BOX_ER01X2")
    return (
      <Suspense fallback="loading">
        {phase != "test" && phase != "end" && info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <BOX_Agents phase={phase} />
      </Suspense>
    );
  if (sku == "FB_ER01")
    return (
      <Suspense fallback="loading">
        {phase != "test" && phase != "end" && info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <ER_Agents phase={phase} />
      </Suspense>
    );
  if (sku == "FB_ER02")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "movie" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <ER_Astro phase={phase} />
      </Suspense>
    );
  if (sku == "FB_ER03")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "menu" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <ER_Labo phase={phase} />
      </Suspense>
    );
  if (sku == "FB_ER04")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "menu" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <ER_Boyard phase={phase} />
      </Suspense>
    );
  if (sku == "FB_MINI2")
    return (
      <Suspense fallback="loading">
        {phase != "test" && phase != "end" && info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <MINI_HelpBunny phase={phase} />
      </Suspense>
    );
  if (sku == "FB_SH01")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "chat" &&
        phase != "welcome" &&
        phase != "testsettings" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <FB_Hero phase={phase} />
      </Suspense>
    );
    if (sku == "SPECIAL_2022")
      return (
        <Suspense fallback="loading">
          {phase != "test" &&
          phase != "end" &&
          phase != "chat" &&
          phase != "welcome" &&
          phase != "testsettings" &&
          info["testmode"] ? (
            <div id="testmode" className="testmode" onMouseOver={move}>
              <div className="header">{t("testmode")}</div>
              <p className="info">{t("testinfo")}</p>
              <p>{t("testanswer")}</p>
              <p id="answer">{t(sku + "_" + phase)}</p>
            </div>
          ) : (
            <div></div>
          )}
          <CHRSPECIAL phase={phase} />
        </Suspense>
      );
  if (sku == "FB_ACT02")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "chat" &&
        phase != "welcome" &&
        phase != "testsettings" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <FG_Hero phase={phase} />
      </Suspense>
    );

   if (sku == "FB_ACT03")
    return (
      <Suspense fallback="loading">
        {phase != "test" &&
        phase != "end" &&
        phase != "chat" &&
        phase != "welcome" &&
        phase != "testsettings" &&
        info["testmode"] ? (
          <div id="testmode" className="testmode" onMouseOver={move}>
            <div className="header">{t("testmode")}</div>
            <p className="info">{t("testinfo")}</p>
            <p>{t("testanswer")}</p>
            <p id="answer">{t(sku + "_" + phase)}</p>
          </div>
        ) : (
          <div></div>
        )}
        <DetectiveGame phase={phase} />
      </Suspense>
    );
}

export default Game;
