import React from "react";
import ReactDOM from "react-dom";
import tv from "../ER_Boyard/imgs/tv.png";
import button from "../ER_Boyard/imgs/micbutton.png";
import button2 from "../ER_Boyard/imgs/locationbutton.png";
import audiogif from "../ER_Boyard/imgs/audio1.gif";
import callaudio from "../ER_Boyard/audio/robin_1.mp3";
import {
  Layout,
  Menu,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import { withTranslation } from "react-i18next";

class Helicopter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NB: "",
      OL: "",
      playing: false,
    };
    this.check = this.check.bind(this);
    this.play = this.play.bind(this);
  }
  check() {
    if (this.state.NB === "241" && this.state.OL === "380")
      window.location.href = "rooms";
  }
  play() {
    var audio = document.getElementById("call");
    audio.play();
    this.setState({ playing: true });
  }
  render() {
    const {t}=this.props;
    return (
      <div
        className="Boyard_Heli"
        style={{ height: "100%", margin: 0, backgroundColor: "#333333" }}
      >
        <Row>
          <Col span={12}>
            {this.state.playing && (
              <img
                src={audiogif}
                style={{
                  position: "absolute",
                  width: "50%",
                  margin: "auto",
                  top: 0,
                  bottom: 0,
                  marginLeft: "25%",
                  padding: 20,
                }}
              />
            )}
            <img src={tv} style={{ width: "70%", marginLeft: "15%" }} />
          </Col>
          <Col span={12}>
            <div
              style={{
                height: "100px",
                display: "block",
                position: "absolute",
                width: "50%",
                margin: "auto",
                top: 0,
                bottom: 0,
                marginLeft: "25%",
                padding: 20,
              }}
            >
              <p
                id="time"
                style={{
                  color: "white",
                  textAlign: "center",
                  lineHeight: "50px",
                  fontSize: 70,
                  fontWeight: 800,
                }}
              >
                TEST
              </p>
            </div>

            <img src={tv} style={{ width: "70%", marginLeft: "15%" }} />
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            <img onClick={this.play} src={button} style={{ width: "150px" }} />
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <p style={{ margin: 0, color: "white", fontSize: 20 }}>
              {t("robin-north")}
            </p>
            <br />
            <Input
              style={{ width: 300, marginBottom: 20 }}
              onChange={(e) => this.setState({ NB: e.target.value })}
              value={this.state.NB}
            />
            <br />
            <p style={{ margin: 0, color: "white", fontSize: 20 }}>
              {t("robin-east")}}
            </p>
            <br />
            <Input
              style={{ width: 300, marginBottom: 20 }}
              onChange={(e) => this.setState({ OL: e.target.value })}
              value={this.state.OL}
            />
            <br />
            <img onClick={this.check} src={button2} style={{ width: "80px" }} />
          </Col>
        </Row>
        <audio src={callaudio} id="call"></audio>
      </div>
    );
  }
}

export default withTranslation(['games'])(Helicopter);
