import React from "react";
import ReactDOM from "react-dom";
import back from "../ER_Labo/imgs/laboback2.jpg";
import backicon from "../ER_Labo/imgs/back2.png";
import style from "../ER_Labo/recept.css";
import { withTranslation } from "react-i18next";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secretVisible: false,
    };
  }
  send() {
    var list = [
      "item1",
      "item2",
      "item3",
      "item4",
      "item5",
      "item6",
      "item7",
      "item8",
    ];
    var truth = [true, false, false, true, false, false, true, false, false];
    for (var i in list) {
      var checked = document.getElementById(list[i]).checked;
      console.log(checked, truth[i]);
      if (checked != truth[i]) return null;
    }
    if (document.getElementById("email").value == "A6H9L@hotmail.com")
      window.location.href = "end";
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Labo_Recept">
        <div class="container">
          <div id="header">
            <h1>{t("labo-sendto")}</h1>
            <center>
              {" "}
              <input type="text" id="email" placeholder="email@email.com" />
              <button id="sendbutton" onClick={this.send}>
                {t("labo-send")}
              </button>
            </center>
          </div>
          <div class="items">
            <input id="item1" type="checkbox" />
            <label for="item1">{t("labo-i1")}</label>

            <input id="item2" type="checkbox" />
            <label for="item2">{t("labo-i2")}</label>

            <input id="item3" type="checkbox" />
            <label for="item3">{t("labo-i3")}</label>

            <input id="item4" type="checkbox" />
            <label for="item4">{t("labo-i4")}</label>

            <input id="item5" type="checkbox" />
            <label for="item5">{t("labo-i5")}</label>

            <input id="item6" type="checkbox" />
            <label for="item6">{t("labo-i6")}</label>

            <input id="item7" type="checkbox" />
            <label for="item7">{t("labo-i7")}</label>

            <input id="item8" type="checkbox" />
            <label for="item8">{t("labo-i8")}</label>

            <input id="item9" type="checkbox" />
            <label for="item9">{t("labo-i9")}</label>

            <input id="item10" type="checkbox" />
            <label for="item10">{t("labo-i10")}</label>

            <input id="item11" type="checkbox" />
            <label for="item11">{t("labo-i11")}</label>

            <input id="item12" type="checkbox" />
            <label for="item12">{t("labo-i12")}</label>

            <input id="item13" type="checkbox" />
            <label for="item13">{t("labo-i13")}</label>

            <input id="item14" type="checkbox" />
            <label for="item14">{t("labo-i14")}</label>

            <input id="item15" type="checkbox" />
            <label for="item15">{t("labo-i15")}</label>

            <input id="item16" type="checkbox" />
            <label for="item16">{t("labo-i16")}</label>

            <input id="item17" type="checkbox" />
            <label for="item17">{t("labo-i17")}</label>

            <input id="item18" type="checkbox" />
            <label for="item18">{t("labo-i18")}</label>

            <h2 class="done" aria-hidden="true">
              {t("labo-recipe")}
            </h2>
            <h2 class="undone" aria-hidden="true">
              {t("labo-notused")}
            </h2>
          </div>
        </div>
        <p id="time"></p>
        <img
          src={backicon}
          id="back"
          onClick={() => (window.location.href = "menu")}
        />
      </div>
    );
  }
}

export default withTranslation(['games'])(Menu);
