import getTime from "../games/time.js";
import $ from "jquery";
import particlesJS from "react-particles-js";

function javascript(message) {
  const
    messageText = document.querySelectorAll(".message__text"),
    messageSplit = message.split(""),
    messageContainer = document.querySelector(".message"),
    messageBtn = document.querySelector(".message__btn");

  const colors = ["#0EAD81", "#A8F5E3", "#63D9AD", "#FFA159"];
  let i = 0;

  //Open Message
  function openMessage() {
    if (messageContainer.classList.contains("clicked")) {
      messageContainer.classList.remove("clicked");
    } else {
      messageContainer.classList.add("clicked");
    }
  }

  openMessage();

  // Get Message ans Split Chars
  messageSplit.forEach(function (el) {
    let template = `
      <p class="message__letters">
         <span class="message__letterMain">${el}</span>
      </p>`;

    messageText.forEach(function (el) {
      el.insertAdjacentHTML("beforeend", template);
    });
  });

  // Add colours to Letters
  const letterMain = document.querySelectorAll(".message__letterMain");
  letterMain.forEach(function (el) {
    if (i == colors.length) i = 0;
    el.style.color = colors[i];
    i++;
  });
  function move() {
    var left = 0;
    document.getElementById("end-questionmark").style.display = "none";
    document.getElementById("win").play();
    const interval = window.setInterval(function () {
      left = left - 3;
      document.getElementById("end-overlay-left").style.left = left + "px";
      document.getElementById("end-overlay-right").style.right = left + "px";
    }, 20);
    setTimeout(function () {
      clearInterval(interval);
    }, 5000);
  }
  document
    .getElementById("end-questionmark")
    .addEventListener("click", function (e) {
      move();
    });

  //var time = getTime()

  // Get today's date and time
  // Display the result in the element with id="demo"
  //  document.getElementById("time").innerHTML =
  // "Jullie slaagden erin te ontsnappen binnen een recordtijd : " +  time[0] + ":" + ("0" + time[1]).slice(-2)+".";
}

export default javascript;
