import $ from "jquery";

function javascript(next_code) {
  var links = {
    0: { 0: "L", 1: "UL", 2: "UD", 3: "UD", 4: "UD", 5: "LD" },
    1: { 0: "LR", 1: "UR", 2: "UD", 3: "LD", 4: "LU", 5: "RD" },
    2: { 0: "RU", 1: "DL", 2: "", 3: "LR", 4: "LR", 5: "L" },
    3: { 0: "", 1: "RU", 2: "UD", 3: "RD", 4: "LR", 5: "LR" },
    4: { 0: "", 1: "", 2: "", 3: "LU", 4: "RD", 5: "LR" },
    5: { 0: "", 1: "LU", 2: "UD", 3: "RD", 4: "", 5: "LR" },
    6: { 0: "LU", 1: "RD", 2: "LU", 3: "UD", 4: "UD", 5: "LRD" },
    7: { 0: "LR", 1: "LU", 2: "RD", 3: "", 4: "LU", 5: "RD" },
    8: { 0: "UR", 1: "R", 2: "", 3: "", 4: "UR", 5: "" },
  };
  var y = 0;
  var x = 0;
  var height = 16.6666666;
  var width = 11.1111111;
  var play = false;
  function check(move) {
    if (!play) {
      var audio = document.getElementById("music");
      audio.play();
      play = true;
    }

    if (links[x][y].includes(move)) {
      return true;
    } else {
      x = 0;
      y = 0;
      document.getElementById("rocket").style.bottom = "0%";
      document.getElementById("rocket").style.right = "0%";
      alert(
        "Pas op! Botsing met een ruimtelichaam! Je zoekt best een plattegrond..."
      );
      return false;
    }
  }
  function up() {
    if (check("U")) {
      y = y + 1;
      y = Math.min(y, 5);
      document.getElementById("rocket").style.bottom =
        (y * height).toString() + "%";
    }
    if (x == 8 && y == 5) {
      window.location.href = next_code;
    }
  }
  function down() {
    if (check("D")) {
      y = y - 1;
      y = Math.max(y, 0);
      document.getElementById("rocket").style.bottom =
        (y * height).toString() + "%";
    }
  }
  function right() {
    if (check("R")) {
      x = x - 1;
      x = Math.max(x, 0);
      document.getElementById("rocket").style.right =
        (x * width).toString() + "%";
    }
  }
  function left() {
    if (check("L")) {
      x = x + 1;
      x = Math.min(x, 8);
      document.getElementById("rocket").style.right =
        (x * width).toString() + "%";
    }
  }

  $("#arrowl").click(left);
  $("#arrowr").click(right);
  $("#arrowu").click(up);
  $("#arrowd").click(down);
}

export default javascript;
