import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import initTime from "../time.js";
import { Redirect } from "react-router-dom";

//game screens
import CodePad from "../../games/ER_Agents/codepad.js";
import Buttons from "../../games/ER_Agents/buttons_box.js";
import Morse from "../../games/ER_Agents/morse.js";
import Alarm from "../../games/ER_Agents/alarm.js";
import Secret from "../../games/ER_Agents/secret.js";
import End from "../../games/end.js";
import Test from "../../games/test.js";
import Start from "../../games/start.js";

import backgroundIMG from "./imgs/start.jpg";

class BOX_Agents extends React.Component {
  componentDidMount() {
    document.title = "Festiviti Escape Room";
    initTime();
  }
  render() {
    if (this.props.match.params.phase == "codepad") return <CodePad />;
    if (this.props.match.params.phase == "play")
      return <Start background={backgroundIMG} escape={true} next="codepad" />;
    if (this.props.match.params.phase == "test")
      return <Test background={backgroundIMG} escape={true} next="codepad" />;
    if (this.props.match.params.phase == "buttons") return <Buttons />;
    if (this.props.match.params.phase == "morse") return <Morse />;
    if (this.props.match.params.phase == "alarm") return <Alarm />;
    if (this.props.match.params.phase == "secret") return <Secret />;
    if (this.props.match.params.phase == "end") return <End />;
    else return <Redirect to="/overview" />;
  }
}

export default withRouter(BOX_Agents);
