import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

function javascript(next_code) {
  var pin = "8184";
  $(document).ready(function ($) {
    $(document).ready(function () {
      var enterCode = "";
      enterCode.toString();

      $("#numbers button").click(function () {
        var clickedNumber = $(this).text().toString();
        enterCode = enterCode + clickedNumber;
        var lengthCode = parseInt(enterCode.length);
        lengthCode--;
        $("#fields .numberfield:eq(" + lengthCode + ")").addClass("active");

        if (lengthCode == 3) {
          // Check the PIN
          if (enterCode == pin) {
            // Right PIN!
            window.location.href = next_code;
          } else {
            // Wrong PIN!
            $("#fields").addClass("miss");
            enterCode = "";
            setTimeout(function () {
              $("#fields .numberfield").removeClass("active");
            }, 200);
            setTimeout(function () {
              $("#fields").removeClass("miss");
            }, 500);
          }
        } else {
        }
      });

      $("#restartbtn").click(function () {
        enterCode = "";
        $("#fields .numberfield").removeClass("active");
        $("#fields .numberfield").removeClass("right");
        $("#numbers").removeClass("hide");
      });
    });
  });
}

export default javascript;
