import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Modal, Input } from "antd";
import egg from "../MINI_HelpBunny/imgs/egg.png";
import egstyleg from "../MINI_HelpBunny/checklist.css";
import c1 from "../MINI_HelpBunny/imgs/coloregg1.png";
import c2 from "../MINI_HelpBunny/imgs/coloregg2.png";
import c3 from "../MINI_HelpBunny/imgs/coloregg3.png";
import c4 from "../MINI_HelpBunny/imgs/coloregg4.png";
import c5 from "../MINI_HelpBunny/imgs/coloregg5.png";
import notebook from "../MINI_HelpBunny/imgs/notebook.png";
import led1 from "../MINI_HelpBunny/imgs/led1.png";
import win from "../ER_Agents/audio/winfx.wav";

class CheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      egg1: false,
      egg2: false,
      egg3: false,
      egg4: false,
      egg5: false,
      message: false,
      code: false,
      codeinput: "Code",
      doce2: false,
      codeinput2: "Code",
    };
    this.audio = new Audio(win);
  }

  checkCode() {
    if (this.state.codeinput == "6708") {
      this.setState({ codeinput: "Code", egg4: true, code: false });
    }
  }
  checkCode2() {
    if (this.state.codeinput2.toLowerCase() == "chocolade!") {
      this.setState({ codeinput2: "Code", egg2: true, code2: false });
    }
  }

  render() {
    const eggstyle = { height: 80, marginRight: 25 };
    var lights = false;
    if (
      this.state.egg1 &&
      this.state.egg2 &&
      this.state.egg3 &&
      this.state.egg4 &&
      this.state.egg5 &&
      !this.state.message
    ) {
      lights = true;
      this.audio.play();
      setTimeout(() => this.setState({ message: true }), 4000);
    }
    return (
      <div
        id="window"
        className="MINI2_Checklist"
        style={{ fontSize: 23, backgroundColor: "#FFFFF5", height: "100%" }}
      >
        <Modal
          title="Nieuwe helpers gevonden!"
          visible={this.state.message}
          width={750}
          footer={null}
        >
          <p
            style={{ fontFamily: "Permanent Marker", fontSize: 20 }}
            className="writing"
          >
            WAUW!!
            <br />
            <br />
            Ik denk dat ik niet verder moet zoeken naar perfecte helpers!
            <br />k heb nu al een officieel diploma opgestuurd. Vanaf nu mag je
            dus net als ik paaseieren verstoppen in je huis of tuin. Ga maar
            snel kijken in de brievenbus, ik heb er nog een verrassing bij
            gestopt!
            <br />
            <br /> Tot snel!
          </p>
        </Modal>
        <Modal
          header={null}
          visible={this.state.code && !this.state.egg4}
          width={400}
          okText="Kraak de code"
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.setState({ code: false })}
          onOk={() => this.checkCode()}
        >
          <Input
            value={this.state.codeinput}
            style={{
              marginTop: 20,
              fontSize: 30,
              fontFamily: "Permanent Marker",
            }}
            onChange={(e) => this.setState({ codeinput: e.target.value })}
          ></Input>
        </Modal>

        <Modal
          header={null}
          visible={this.state.code2 && !this.state.egg2}
          width={400}
          okText="Bewijs je kennis"
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.setState({ code2: false })}
          onOk={() => this.checkCode2()}
        >
          <Input
            value={this.state.codeinput2}
            style={{
              marginTop: 20,
              fontSize: 30,
              fontFamily: "Permanent Marker",
            }}
            onChange={(e) => this.setState({ codeinput2: e.target.value })}
          ></Input>
        </Modal>

        <audio src={win} id="win"></audio>
        <img
          src={notebook}
          style={{
            position: "absolute",
            bottom: 0,
            width: 900,
            left: "50%",
            marginLeft: -450,
          }}
        />
        <div style={{ visibility: lights ? "visible" : "hidden" }}>
          <div
            className="lights"
            style={{
              width: 100,
              height: "100%",
              position: "absolute",
              left: 0,
            }}
          />
          <div
            className="lights"
            style={{
              width: 100,
              height: "100%",
              position: "absolute",
              right: 0,
            }}
          />
        </div>

        <div
          style={{
            display: "block",
            width: 900,
            height: 500,
            position: "absolute",
            bottom: 0,
            left: "50%",
            marginLeft: -450,
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            Wat moet je kunnen als helper?
          </h3>
          <Col span={16} offset={4}>
            <div
              onClick={() => this.setState({ egg1: !this.state.egg1 })}
              style={{
                height: 60,
                margin: 20,
                textDecoration: this.state.egg1 ? "line-through" : "none",
              }}
            >
              <img src={this.state.egg1 ? c1 : egg} style={eggstyle} />
              1. Goed kunnen luisteren.
            </div>
            <div
              onClick={() => this.setState({ code2: true })}
              style={{
                height: 60,
                margin: 20,
                textDecoration: this.state.egg2 ? "line-through" : "none",
              }}
            >
              <img src={this.state.egg2 ? c2 : egg} style={eggstyle} />
              2. Beschikken over Paas-kennis.
            </div>
            <div
              onClick={() => this.setState({ egg3: !this.state.egg3 })}
              style={{
                height: 60,
                margin: 20,
                textDecoration: this.state.egg3 ? "line-through" : "none",
              }}
            >
              <img src={this.state.egg3 ? c3 : egg} style={eggstyle} />
              3. Voldoende hoog, snel en ver springen.
            </div>
            <div
              onClick={() => this.setState({ code: true })}
              style={{
                height: 60,
                margin: 20,
                textDecoration: this.state.egg4 ? "line-through" : "none",
              }}
            >
              <img src={this.state.egg4 ? c4 : egg} style={eggstyle} />
              4. Kraak de Paascode.
            </div>
            <div
              onClick={() => this.setState({ egg5: !this.state.egg5 })}
              style={{
                height: 60,
                margin: 20,
                textDecoration: this.state.egg5 ? "line-through" : "none",
              }}
            >
              <img src={this.state.egg5 ? c5 : egg} style={eggstyle} />
              5. Paaseieren eerlijk verdelen.
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default CheckList;
