import React from "react";
import ReactDOM from "react-dom";
import javascript from "../games/end.jsx";
import styles from "../games/end.css";
import win from "../games/ER_Agents/audio/winfx.wav";
import getFinishTime from "../games/time.js";
import { withTranslation } from "react-i18next";

class End extends React.Component {

  constructor(props) {
    super(props);
    var time = new Date(parseInt(localStorage.getItem("festiviti_start_time")));
    var extra_minutes = parseInt(localStorage.getItem("festiviti_extra_time"));
    var punish_time = parseInt(localStorage.getItem("festiviti_punish_time"));
    var now = new Date().getTime();
    this.minutes =
      Math.floor(((now - time) % (1000 * 60 * 60)) / (1000 * 60)) +
      extra_minutes +
      punish_time;
    this.seconds = Math.floor(((now - time) % (1000 * 60)) / 1000);
  }
  componentDidMount() {
    const {t}=this.props;
    javascript(t("end-happy"));
    //document.getElementById('win').play();
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window">
        <p id="end-questionmark">?</p>
        <div id="end-overlay-left"></div>
        <div id="end-overlay-right"></div>
        <div className="ending">
          <audio src={win} id="win"></audio>
          <div className="text clock">
            <p>
              {this.minutes}:{this.seconds<9? "0": ""}{this.seconds}
            </p>
          </div>

          <div className="message">
            <button className="message__btn">click me</button>
            <p className="message__text"></p>
          </div>
          <img src="/imgs/logo.png" id="logo" />
          <div className="text">
            <p>
            {t("end-time").replace('SS',this.seconds).replace('MM',this.minutes)}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(End);
