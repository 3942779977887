import React from "react";
import ReactDOM from "react-dom";
//import javascript from '../ER_Astro/phone.jsx'
import styles from "../ER_Labo/wires.css";
import screw1 from "../ER_Labo/imgs/screw1.png";
import screw2 from "../ER_Labo/imgs/screw2.png";
import screw3 from "../ER_Labo/imgs/screw3.png";
import screw4 from "../ER_Labo/imgs/screw4.png";
import red from "../ER_Labo/imgs/cablered.png";
import green from "../ER_Labo/imgs/cablegreen.png";
import yellow from "../ER_Labo/imgs/cableyellow.png";
import ledred from "../ER_Labo/imgs/ledred.png";
import ledgreen from "../ER_Labo/imgs/ledgreen.png";
import greencut from "../ER_Labo/imgs/cablegreen-cut.png";
import yellowcut from "../ER_Labo/imgs/cableyellow-cut.png";
import redcut from "../ER_Labo/imgs/cablered-cut.png";
import { Popconfirm } from "antd";
import { withTranslation } from "react-i18next";

class Wires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screws: [0, 0, 0, 0],
      moving: false,
      cut: "",
    };
    this.next = this.next.bind(this);
  }
  open() {
    if (
      this.state.screws[0] == 135 &&
      this.state.screws[1] == 0 &&
      this.state.screws[2] == 90 &&
      this.state.screws[3] == 45
    ) {
      this.setState({ moving: true });
      const el = document.getElementById("cover");
      function move() {
        topPos = topPos + 2;
        el.style.marginTop = topPos + "px";
        var rect = el.getBoundingClientRect();
        if (window.screen.height - rect.top - 130 < 0) {
          clearInterval(id);
          document.getElementById("covercontainer").style.display = "none";
        }
      }
      var id;
      var topPos = 0;
      id = setInterval(move, 25);
    }
    console.log(this.state);
  }
  next() {
    const {t}=this.props;
    this.setState({ cut: "red" });
    document.getElementById("ledtext").innerHTML = t("open")
    setTimeout(function () {
      window.location.href = "codepad";
    }, 10000);
  }
  handleScrew(number) {
    var screws = this.state.screws;
    screws[number] = (screws[number] + 45) % 180;
    this.setState({ screws: screws });
    console.log(this.state.screws);
    this.open();
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Labo_Wires">
        <div id="metal-left"></div>
        <div id="metal-right"></div>
        <div id="metal-top"></div>
        <div id="covercontainer">
          <div id="cover">
            <img
              id="screw1"
              onClick={() => this.handleScrew(0)}
              style={{ transform: `rotate(${this.state.screws[0]}deg)` }}
              class="screw"
              src={screw1}
            />
            <img
              id="screw2"
              onClick={() => this.handleScrew(1)}
              style={{ transform: `rotate(${this.state.screws[1]}deg)` }}
              class="screw"
              src={screw2}
            />
            <img
              id="screw3"
              onClick={() => this.handleScrew(2)}
              style={{ transform: `rotate(${this.state.screws[2]}deg)` }}
              class="screw"
              src={screw3}
            />
            <img
              id="screw4"
              onClick={() => this.handleScrew(3)}
              style={{ transform: `rotate(${this.state.screws[3]}deg)` }}
              class="screw"
              src={screw4}
            />
          </div>
        </div>

        <div id="cables">
          <div id="buffer"></div>

          <Popconfirm
            title={
              <p>
                {t("labo-wire-alert")}
              </p>
            }
            onConfirm={this.next}
            okText={t("labo-cut")}
            cancelText={t("labo-back")}
          >
            <div>
              <div class="number">1</div>
              <img
                id="red"
                class="cable"
                src={this.state.cut == "red" ? redcut : red}
              />{" "}
            </div>{" "}
          </Popconfirm>

          <Popconfirm
            title={
              <p>
                {t("labo-wire-alert")}
              </p>
            }
            onConfirm={() => this.setState({ cut: "yellow" })}
            okText={t("labo-cut")}
            cancelText={t("labo-back")}
          >
            <div>
              <div class="number">2</div>
              <img
                id="yellow"
                class="cable"
                src={this.state.cut == "yellow" ? yellowcut : yellow}
              />
            </div>
          </Popconfirm>

          <Popconfirm
            title={
              <p>
                {t("labo-wire-alert")}
              </p>
            }
            onConfirm={() => this.setState({ cut: "green" })}
            okText={t("labo-cut")}
            cancelText={t("labo-back")}
            width={400}
          >
            <div>
              <div class="number">3</div>
              <img
                id="green"
                class="cable"
                src={this.state.cut == "green" ? greencut : green}
              />
            </div>
          </Popconfirm>
        </div>
        <div id="timecontainer">
          <p id="time"></p>
        </div>

        <div id="ledcontainer">
            {t("labo-doors")}
          <br />
          <div id="doors">
            <img id="led" src={this.state.cut == "red" ? ledgreen : ledred} />
            <div id="ledtext">{t("labo-closed")}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(Wires);
