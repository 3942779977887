import React, { Component, ScrollView, useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import {
  Layout,
  Menu,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
} from "antd";
import { InfoCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from "../imgs/logo.png";
import tile from "../imgs/tile.png";
import Action from "./Action";
import FAQ from "./FAQ";
import Reductions from "./Reductions";
import styles from "../styles/login.css";
import fotochallenge from "../imgs/fotochallenge.jpg";

const { Meta } = Card;
const { Header, Content, Footer, Sider } = Layout;

function fetchGames() {
  const user = JSON.parse(localStorage.getItem("user"));
  return fetch(process.env.REACT_APP_BACKEND + "/api/group_game_list", {
    method: "GET",
    headers: { Authorization: "Bearer " + user.token },
  }).then((response) => response.json());
}

const LoadingCard = () => {
  return (
    <Col className="gutter-row" style={{ width: 480 }}>
      <Card style={{ margin: 20, height: 460, width: 450 }}>
        <Skeleton loading={true} active>
          <Meta title="Card title" description="This is the description" />
        </Skeleton>
        <Skeleton loading={true} active>
          <Meta title="Card title" description="This is the description" />
        </Skeleton>
      </Card>
    </Col>
  );
};
class GroupOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      modalVisible: true,
      popupVisible: false,
      sku: "",
      order: "",
      selectedItem: "1",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ popupVisible: true });
    }, 3000);
    fetchGames()
      .then((response) => {
        this.setState({ games: response });
      })
      .catch();
  }
  logout() {
    localStorage.setItem("user", null);
    window.location.href = "";
  }
  render() {
    const { t } = this.props;
    if (this.state.selectedItem == "4") {
      window.location.href = "https://festiviti.eu/pages/festiviti-party";
    }
    return (
      <Layout style={{ background: "white" }}>
        <Header className="header" style={{ backgroundColor: "white" }}>
          <img
            className="logo"
            src={logo}
            style={{ width: 180, margin: "15px 0px 0 0", float: "left" }}
          ></img>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[this.state.selectedItem]}
            onClick={(e) => this.setState({ selectedItem: e.key })}
          >
            <Menu.Item key="1">Jouw Activiteiten</Menu.Item>
            <Menu.Item key="2">Veelgestelde Vragen</Menu.Item>
            <Menu.Item key="4">Alle Activiteiten</Menu.Item>
            <Button
              id="logout"
              onClick={this.logout}
              style={{ float: "right", marginTop: 15 }}
            >
              Uitloggen
            </Button>
          </Menu>
        </Header>
        <Modal
          title="Ken je de Festiviti foto-wedstrijd al?"
          visible={this.state.popupVisible}
          onCancel={() => this.setState({ popupVisible: false })}
          footer={[
            <Button
              key="info"
              onClick={() =>
                this.setState({ popupVisible: false, selectedItem: "3" })
              }
              style={{
                background: "#F0730D",
                color: "white",
                borderColor: "#F0730D",
              }}
            >
              Meer Info
            </Button>,
          ]}
        >
          <p style={{ textAlign: "center", fontWeight: 600 }}>
            Win elke maand een knutselpakket t.w.v. 60 euro:
          </p>
          <img src={fotochallenge} style={{ width: 300, marginLeft: 75 }} />
        </Modal>
        <div style={{ width: 960, display: "block", margin: "auto" }}>
          {this.state.selectedItem == "1" && (
            <Row gutter={16}>
              {this.state.games.length == 0 && LoadingCard()}
              {this.state.games.length == 0 && LoadingCard()}

              {this.state.games.map(function (game, i) {
                if (!game.info.hidden && game.info.type != "GIFT")
                  return (
                    <Col className="gutter-row" style={{ width: 480 }}>
                      <GameCard
                        game={game}
                        showReduction={() => {
                          this.setState({ selectedItem: "3" });
                        }}
                      />
                    </Col>
                  );
              }, this)}
            </Row>
          )}
          {this.state.selectedItem == "2" && <FAQ />}
          {this.state.selectedItem == "3" && (
            <Reductions code={this.state.code} friends={this.state.count} />
          )}
        </div>
      </Layout>

      // {this.state.games.length ==0 && <div className="loading">Je bestellingen zijn aan het laden...</div>}
      //{this.state.games.map(function(game, i){
      //  return (<Game game={game} />);
      //  })}
    );
  }
}
async function play_game(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: false };
  localStorage.setItem("game_info", JSON.stringify(info));
  var user = JSON.parse(localStorage.getItem("user"));
  const response = await fetch(
    process.env.REACT_APP_BACKEND +
      "/api/activate?game=" +
      info["game"] +
      "&order=" +
      info["order"],
    { method: "GET", headers: { Authorization: "Bearer " + user.token } }
  ).catch((error) =>
    alert("Something went wrong. Contact us at info@festiviti.eu")
  );
  window.location.href = "/game/" + sku + "/play";
}
function play_again(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: false };
  localStorage.setItem("game_info", JSON.stringify(info));
  window.location.href = "/game/" + sku + "/play";
}
function test_game(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: true };
  console.log(info);
  localStorage.setItem("game_info", JSON.stringify(info));
  console.log(JSON.parse(localStorage.getItem("game_info")));
  window.location.href = "/game/" + sku + "/test";
}

function GameCard(props) {
  const { t, i18n } = useTranslation();
  const imgstyle1 = { height: 270 };
  const imgstyle2 = { height: 270, filter: "brightnes(50%)" };
  const [modalVisible, setModalVisible] = useState(0);
  const [activating, setActivating] = useState(false);
  const date = new Date(Date.parse(props.game.start)).toLocaleDateString(
    i18n.language
  );
  const stopdate = new Date(Date.parse(props.game.stop)).toLocaleDateString(
    i18n.language
  );
  return (
    <div>
      <Modal
        title="Spel Activeren"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setModalVisible(false);
            }}
          >
            Annuleren
          </Button>,
          <Button
            style={{ background: "#F0730D", borderColor: "#F0730D" }}
            key="submit"
            type="primary"
            loading={activating}
            onClick={() => {
              setActivating(true);
              play_game(props.game.sku, props.game.order);
            }}
          >
            Spel Activeren
          </Button>,
        ]}
      >
        <p>
          Je staat op het punt om dit spel te activeren. Vanaf activatie blijft
          je toegang 48u geldig.{" "}
        </p>
        <p>
          Ga je pas later spelen? Gebruik dan nu de test-modus om alles al te
          bekijken.
        </p>
      </Modal>
      <Card
        style={{ margin: 20, height: 460, width: 450 }}
        headStyle={{ color: "#F0730D" }}
        cover={
          <img
            alt="example"
            src={"/imgs/games/" + props.game.sku + ".png"}
            style={props.game.valid ? imgstyle1 : imgstyle2}
          />
        }
      >
        {!props.game.active ? (
          <Meta description={"Toegang vanaf " + date} />
        ) : (
          <Meta description={"Toegang vervalt op " + stopdate} />
        )}
        {!props.game.active && (
          <div style={{ height: 100, display: "block", margin: "0 auto" }}>
            {t(props.game.sku + "_DESC")}{" "}
          </div>
        )}
        {props.game.active && props.game.valid && (
          <div style={{ height: 100, display: "block", margin: "0 auto" }}>
            {" "}
            <ClockCircleOutlined style={{ margin: 10 }} />
            Je hebt toegang tot dit spel tot {stopdate}. Neem contact op met
            Festiviti om de speelperiode te verlengen.
          </div>
        )}
        {props.game.active && !props.game.valid && (
          <div style={{ height: 100, display: "block", margin: "0 auto" }}>
            <ClockCircleOutlined style={{ margin: 10 }} />
            Je hebt geen toegang meer tot dit spel.
            <br />
            Je school of vereniging kan contact opnemen met Festiviti om de
            speelperiode te verlengen.
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          {props.game.valid ? (
            <div>
              {props.game.info.testmode && (
                <Button
                  type="primary"
                  style={{ background: "#F0730D", borderColor: "#F0730D" }}
                  onClick={(e) => test_game(props.game.sku, props.game.order)}
                >
                  Spel Testen
                </Button>
              )}
              {props.game.active ? (
                <Button
                  type="primary"
                  style={{
                    marginLeft: 20,
                    background: "#F0730D",
                    borderColor: "#F0730D",
                  }}
                  onClick={(e) => {
                    if (!props.game.activated) setModalVisible(true);
                    else play_again(props.game.sku, props.game.order);
                  }}
                >
                  Spel Spelen
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    marginLeft: 20,
                    background: "#F0730D",
                    borderColor: "#F0730D",
                  }}
                  disabled
                >
                  Spel Spelen
                </Button>
              )}{" "}
            </div>
          ) : (
            <Button
              type="primary"
              style={{ background: "#F0730D", borderColor: "#F0730D" }}
              href={t(props.game.sku + "_REVIEW")}
            >
              Review Schrijven
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}
export default withTranslation()(GroupOverview);
