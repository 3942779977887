import React from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "antd";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/suspects.css";
import service from "../FB_Hero/imgs/secret_service.jpeg";
import loading from "../FB_Hero/imgs/call.gif";
import hero from "../FB_Hero/imgs/superhero.jpg";
import c1 from "../FB_Hero/imgs/criminal1.jpg";
import c2 from "../FB_Hero/imgs/criminal2.jpg";
import c3 from "../FB_Hero/imgs/criminal3.jpg";
import c4 from "../FB_Hero/imgs/criminal4.jpg";
import c5 from "../FB_Hero/imgs/criminal5.jpg";
import c6 from "../FB_Hero/imgs/criminal6.jpg";
import c7 from "../FB_Hero/imgs/criminal7.jpg";
import c8 from "../FB_Hero/imgs/criminal8.jpg";

class Suspects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: [1, 1, 1, 1, 1, 1, 1, 1],
      modal1: false,
      modal2: false,
      text: "",
    };

    this.setSuspect = this.setSuspect.bind(this);
    this.send = this.send.bind(this);
  }
  setSuspect(i) {
    var s = [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5];
    s[i] = 1;
    this.setState({ opacity: s });
  }
  send() {
    this.setState({ modal1: true, text: "Gegevens van dader verzenden..." });
    setTimeout(() => {
      this.setState({ modal1: true, text: "Robotfoto verzenden..." });
    }, 2000);
    setTimeout(() => {
      this.setState({ modal1: true, text: "Politie waarschuwen..." });
    }, 4000);
    setTimeout(() => {
      this.setState({ modal1: false, modal2: true });
    }, 6000);
  }
  render() {
    return (
      <div className="Hero_Suspects">
        <Modal visible={this.state.modal1} title="Dader Aangeven" footer={[]}>
          <img src={loading} style={{ width: 300, marginLeft: 100 }} />
          <p
            id="title"
            style={{ width: "100%", textAlign: "center", fontSize: "23px" }}
          >
            {this.state.text}
          </p>
        </Modal>

        <Modal
          visible={this.state.modal2}
          title="Gelukt!"
          footer={[
            <Button onClick={() => (window.location.href = "panel")}>
              Open Bedieningspaneel
            </Button>,
          ]}
        >
          <img src={hero} style={{ width: 250, marginLeft: 125 }} />
          <p
            id="title"
            style={{ width: "100%", textAlign: "center", fontSize: "17px" }}
          >
            De dader is doorgegeven aan de politie.
            <br />
            Jullie hebben een pauze verdiend!
            <br />
            <br />
            Om de volledige zaak op te lossen, vinden jullie best ook de locatie
            van de buit.
          </p>
        </Modal>
        <h1>Overzicht van alle daders</h1>
        <div class="container">
          <div class="tilt">
            <img
              id="1"
              src={c1}
              className="profilepic"
              onClick={() => this.setSuspect(0)}
              style={{ opacity: this.state.opacity[0] }}
            />
          </div>
          <div class="tilt">
            <img
              id="2"
              src={c2}
              className="profilepic"
              onClick={() => this.setSuspect(1)}
              style={{ opacity: this.state.opacity[1] }}
            />
          </div>
          <div class="tilt">
            <img
              id="3"
              src={c3}
              className="profilepic"
              onClick={() => this.setSuspect(2)}
              style={{ opacity: this.state.opacity[2] }}
            />
          </div>
          <div class="tilt">
            <img
              id="4"
              src={c4}
              className="profilepic"
              onClick={() => this.setSuspect(3)}
              style={{ opacity: this.state.opacity[3] }}
            />
          </div>
          <div class="tilt">
            <img
              id="5"
              src={c5}
              className="profilepic"
              onClick={() => this.setSuspect(4)}
              style={{ opacity: this.state.opacity[4] }}
            />
          </div>
          <div class="tilt">
            <img
              id="6"
              src={c6}
              className="profilepic"
              onClick={() => this.setSuspect(5)}
              style={{ opacity: this.state.opacity[5] }}
            />
          </div>
          <div class="tilt">
            <img
              id="7"
              src={c7}
              className="profilepic"
              onClick={() => this.setSuspect(6)}
              style={{ opacity: this.state.opacity[6] }}
            />
          </div>
          <div class="tilt">
            <img
              id="8"
              src={c8}
              className="profilepic"
              onClick={() => this.setSuspect(7)}
              style={{ opacity: this.state.opacity[7] }}
            />
          </div>
          <button
            className="getuigen_knop"
            type="button"
            onClick={() => (window.location.href = "witness")}
          >
            Terug naar de getuigen
          </button>
          <button className="knop_daders" type="button" onClick={this.send}>
            Dader doorgeven aan politie
          </button>
        </div>
      </div>
    );
  }
}

export default Suspects;
