import React, { Component, ScrollView, useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Download from './Download'
import {
  Layout,
  Menu,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,Dropdown
} from "antd";
import i18n from "../i18n.js";
import { InfoCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from "../imgs/logo.png";
import tile from "../imgs/tile.png";
import Action from "./Action";
import FAQ from "./FAQ";
import Reductions from "./Reductions";
import styles from "../styles/login.css";
import fotochallenge from "../imgs/fotochallenge.jpg";

const { Meta } = Card;
const { Header, Content, Footer, Sider } = Layout;

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a onClick={()=>{i18n.changeLanguage('nl')}}>Nederlands</a>
    </Menu.Item>
  </Menu>
);

function fetchGames() {
  const user = JSON.parse(localStorage.getItem("user"));
  return fetch(process.env.REACT_APP_BACKEND + "/api/game_list", {
    method: "GET",
    headers: { Authorization: "Bearer " + user.token },
  }).then((response) => response.json());
}

function fetchReferral() {
  const user = JSON.parse(localStorage.getItem("user"));
  return fetch(process.env.REACT_APP_BACKEND + "/api/referral", {
    method: "GET",
    headers: { Authorization: "Bearer " + user.token },
  }).then((response) => response.json());
}

const LoadingCard = () => {
  return (
    <Col className="gutter-row" style={{ width: 480 }}>
      <Card style={{ margin: 20, height: 460, width: 450 }}>
        <Skeleton loading={true} active>
          <Meta title="Card title" description="This is the description" />
        </Skeleton>
        <Skeleton loading={true} active>
          <Meta title="Card title" description="This is the description" />
        </Skeleton>
      </Card>
    </Col>
  );
};
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      modalVisible: true,
      popupVisible: false,
      sku: "",
      order: "",
      menuSelect: "1",
    };
  }

  componentDidMount() {
    console.log("mount");
    setTimeout(() => {
      this.setState({ popupVisible: true });
    }, 3000);
    fetchGames()
      .then((response) => {
        this.setState({ games: response });
      })
      .catch();
    fetchReferral()
      .then((response) => {
        this.setState({ code: response.code, count: response.count });
      })
      .catch();
  }
  logout() {
    localStorage.setItem("user", null);
    window.location.href = "";
  }
  render() {
    console.log(this.state);
    const { t } = this.props;
    if (this.state.menuSelect == "4") {
      window.location.href = "https://festiviti.eu/pages/festiviti-party";
    }
    return (
      <Layout style={{ background: "white" }}>
        <Header className="header" style={{ backgroundColor: "white" }}>
          <img
            className="logo"
            src={logo}
            style={{ width: 180, margin: "15px 0px 0 0", float: "left" }}
          ></img>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[this.state.menuSelect]}
            onClick={(e) => this.setState({ menuSelect: e.key })}
          >
            <Menu.Item key="1">{t("menu-activities")}</Menu.Item>
            <Menu.Item key="2">{t("menu-questions")}</Menu.Item>
            <Menu.Item key="3">{t("menu-promo")}</Menu.Item>
            <Button
              id="logout"
              onClick={this.logout}
              style={{ float: "right", marginTop: 15, marginRight: 10 }}
            >
              {t("menu-logout")}
            </Button>
          </Menu>
        </Header>
        <Content style={{minHeight :"calc(100vh - 130px)"}}>
        <Modal
          title={t("challenge-header")}
          visible={this.state.popupVisible}
          onCancel={() => this.setState({ popupVisible: false })}
          footer={[
            <Button
              key="info"
              onClick={() =>
                this.setState({ popupVisible: false, menuSelect: "3" })
              }
              style={{
                background: "#F0730D",
                color: "white",
                borderColor: "#F0730D",
              }}
            >
            {t("challenge-more")}
            </Button>,
          ]}
        >
          <p style={{ textAlign: "center", fontWeight: 600 }}>
          {t("challenge-desc")}
          </p>
          <img src={fotochallenge} style={{ width: 300, marginLeft: 75 }} />
        </Modal>
        <div style={{ width: 960, display: "block", margin: "auto" }}>
          {this.state.menuSelect == "1" && (
            <Row gutter={16}>
              {this.state.games.length == 0 && LoadingCard()}
              {this.state.games.length == 0 && LoadingCard()}

              {this.state.games.map(function (game, i) {
                if (!game.info.hidden && game.info.type != "GIFT")
                  return (
                    <Col className="gutter-row" style={{ width: 480 }}>
                      <GameCard
                        game={game}
                        showReduction={() => {
                          this.setState({ menuSelect: "3" });
                        }}
                      />
                    </Col>
                  );
                if (!game.info.hidden && game.info.type == "GIFT")
                  return (
                    <Col className="gutter-row" style={{ width: 480 }}>
                      <GiftCard game={game} />
                    </Col>
                  );
              }, this)}
            </Row>
          )}
          {this.state.menuSelect == "2" && <FAQ />}
          {this.state.menuSelect == "3" && (
            <Reductions code={this.state.code} friends={this.state.count} />
          )}
        </div>
        </Content>
        <Footer style={{height : "70px"}}>
        <span>{t("footer-contact")}</span>
        <Dropdown overlay={menu} trigger={['click']}>
        <Button
          id="lan" style={{ float: "right"}}>
          Change Language
        </Button>
        </Dropdown>
        </Footer>
      </Layout>

      // {this.state.games.length ==0 && <div className="loading">Je bestellingen zijn aan het laden...</div>}
      //{this.state.games.map(function(game, i){
      //  return (<Game game={game} />);
      //  })}
    );
  }
}
async function play_game(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: false };
  localStorage.setItem("game_info", JSON.stringify(info));
  var user = JSON.parse(localStorage.getItem("user"));
  const response = await fetch(
    process.env.REACT_APP_BACKEND +
      "/api/activate?game=" +
      info["game"] +
      "&order=" +
      info["order"],
    { method: "GET", headers: { Authorization: "Bearer " + user.token } }
  ).catch((error) =>
    alert("Something went wrong. Contact us at info@festiviti.eu")
  );
  window.location.href = "/game/" + sku + "/play";
}
function play_again(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: false };
  localStorage.setItem("game_info", JSON.stringify(info));
  window.location.href = "/game/" + sku + "/play";
}
function test_game(sku, ordernumber) {
  var info = { order: ordernumber, game: sku, testmode: true };
  console.log(info);
  localStorage.setItem("game_info", JSON.stringify(info));
  console.log(JSON.parse(localStorage.getItem("game_info")));
  window.location.href = "/game/" + sku + "/test";
}
function GiftCard(props) {
  const { t, i18n } = useTranslation(['translation', 'games']);
  const imgstyle1 = { height: 270 };
  const imgstyle2 = { height: 270, filter: "brightnes(50%)" };
  const [code, setCode] = useState("...");
  const date = new Date(Date.parse(props.game.order_date)).toLocaleDateString(
    i18n.language
  );
  useEffect(() => {
    const fetchGiftCode = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        fetch(
          process.env.REACT_APP_BACKEND +
            "/api/gift?order_id=" +
            props.game.order,
          { method: "GET", headers: { Authorization: "Bearer " + user.token } }
        )
          .then((response) => response.json())
          .then((response) => setCode(response["code"]));
      } catch (e) {
        console.log(e);
      }
    };
    fetchGiftCode();
  }, []);
  return (
    <div>
      <Card
        style={{ margin: 20, height: 510, width: 450 }}
        headStyle={{ color: "#F0730D" }}
        cover={
          <img
            alt="example"
            src={"/imgs/games/" + props.game.sku + ".png"}
            style={props.game.valid ? imgstyle1 : imgstyle2}
          />
        }
      >
        <Meta description={"Aangekocht op " + date} />
        <div style={{ height: 50, display: "block", margin: "0 auto" }}>
          {t(props.game.sku + "_DESC")}{" "}
        </div>
        <div style={{ textAlign: "center" }}>
          <p style={{ fontWeight: 700, fontSize: 20 }}>
            {t('gift-code')} <span style={{ color: "#F0730D" }}>{code}</span>
          </p>
          <Button
            type="primary"
            href="/downloads/escaperoom-cadeaubon.pdf"
            style={{ background: "#F0730D", borderColor: "#F0730D" }}
            download
          >
          {t('gift-download')}
          </Button>
        </div>
      </Card>
    </div>
  );
}
function GameCard(props) {
  const { t, i18n } = useTranslation();
  const imgstyle1 = { height: 270 };
  const imgstyle2 = { height: 270, filter: "brightnes(50%)" };
  const [modalVisible, setModalVisible] = useState(0);
  const [downloadVisible, setDownloadVisible] = useState(0);
  const [activating, setActivating] = useState(false);
  const date = new Date(Date.parse(props.game.order_date)).toLocaleDateString(
    i18n.language
  );
  return (
    <div>
    <Download game={props.game} visible={downloadVisible} hide={() => setDownloadVisible(false)}/>
      <Modal
        title={t("card-activate")}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setModalVisible(false);
            }}
          >
            {t("cancel")}
          </Button>,
          <Button
            style={{ background: "#F0730D", borderColor: "#F0730D" }}
            key="submit"
            type="primary"
            loading={activating}
            onClick={() => {
              setActivating(true);
              play_game(props.game.sku, props.game.order);
            }}
          >
            {t("card-activate")}
          </Button>,
        ]}
      >
        <p>{t("alert-activate")}</p>
        <p>{t("alert-later")}</p>
      </Modal>
      <Card
        style={{ margin: 20, height: 510, width: 450 }}
        headStyle={{ color: "#F0730D" }}
        cover={
          <img
            alt="example"
            src={"/imgs/games/" + props.game.sku + ".png"}
            style={props.game.valid ? imgstyle1 : imgstyle2}
          />
        }
      >
        <Meta description={t("card-time")+" " + date} />
        {!props.game.activated && (
          <div style={{ height: 160, display: "block", margin: "0 auto" }}>
            {t(props.game.sku + "_DESC",{ ns: 'games' })}
            { props.game.info.type !== "BOX" && props.game.info.type !== "OFFLINE" && <p>
              <b>
              {t("card-step1")}<br />{t("card-step2")}<br />{t("card-step3")}
              </b>
            </p> }
            { props.game.info.type == "OFFLINE" && <p>
              <b>
              {t("card-step1")}<br />{t("card-step2-offline")}<br />{t("card-step3-offline")}
              </b>
            </p> }
             { props.game.info.type === "BOX" && <p>
              <b>{t("card-box")}</b>
            </p> }
          </div>
        )}
        {props.game.info.type == "GIFT" && <p>GIFT CODE</p>}
        {props.game.activated && props.game.valid && (
          <div style={{ height: 100, display: "block", margin: "0 auto" }}>
            {" "}
            <ClockCircleOutlined style={{ margin: 10 }} />
            {t("card-active")}
          </div>
        )}
        {props.game.activated && !props.game.valid && (
          <div style={{ height: 100, display: "block", margin: "0 auto" }}>
            <ClockCircleOutlined style={{ margin: 10 }} />
            {t("card-inactive")}
            <br />
            <b>  {t("card-free")}</b>{" "}
            <a onClick={() => props.showReduction()}>{t("card-friends")}</a>
            <br />{t("card-noplay")}
             {" "}
            <a href="mailto:info@festiviti.eu">{t("card-contact")}</a>
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          {props.game.valid ? (
            <div>
              <Button
                type="primary"
                style={{ background: "#F0730D", borderColor: "#F0730D" }}
                onClick={()=>{setDownloadVisible(true)}}>
                {t("card-download")}
              </Button>
              {props.game.info.testmode && (
                <Button
                  type="primary"
                  style={{
                    marginLeft: 20,
                    background: "#F0730D",
                    borderColor: "#F0730D",
                  }}
                  onClick={(e) => test_game(props.game.sku, props.game.order)}
                >
                  {t("card-test")}
                </Button>
              )}
              {props.game.info.type !== "OFFLINE" &&
              <Button
                type="primary"
                style={{
                  marginLeft: 20,
                  background: "#F0730D",
                  borderColor: "#F0730D",
                }}
                onClick={(e) => {
                  if (!props.game.activated) setModalVisible(true);
                  else play_again(props.game.sku, props.game.order);
                }}
              >
                {props.game.activated ? t("card-again") : t("card-play")}
              </Button>}
            </div>
          ) : (
            <Button
              type="primary"
              style={{ background: "#F0730D", borderColor: "#F0730D" }}
              href={t(props.game.sku + "_REVIEW")}
            >
              {t("review")}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}
export default withTranslation()(Homepage);
