import React from "react";
import ReactDOM from "react-dom";
import { withTranslation} from "react-i18next";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/witness_overview.css";
import service from "../FB_Hero/imgs/secret_service.jpeg";
import p1 from "../FB_Hero/imgs/profile1.png";
import p2 from "../FB_Hero/imgs/profile2.png";
import p3 from "../FB_Hero/imgs/profile3.png";
import p4 from "../FB_Hero/imgs/profile4.png";
import p5 from "../FB_Hero/imgs/profile5.png";

class WitnessOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: "",
    };
    this.show = this.show.bind(this);
    this.chat = this.chat.bind(this);
  }
  check() {
    if (document.getElementById("wachtwoord").value == "superman") {
      window.location.href = "welcome";
    }
  }
  show(person, path) {
    const { t } = this.props;
    this.setState({ person: person });
    document.getElementById("avatar").src = path;
    document.getElementById("form").style.display = "block";
  }
  chat() {
    const { t } = this.props;
    const password = document.getElementById("password").value;
    console.log(t("FB_SH01_password_" + this.state.person));
    if (t("FB_SH01_password_" + this.state.person) == password) {
      window.location.href = "chat?p=" + this.state.person;
    }
  }
  render() {
    const { t} = this.props;
    return (
      <div className="Hero_WOverview">
        <h1>Getuigen Contacteren</h1>
        <div class="dossiers">
          <div class="dossier" onClick={() => this.show("cleaner", p1)}>
            <h2>{t("FB_SH01_cleaner")}</h2>
            <img src={p1} />
          </div>
          <div class="dossier" onClick={() => this.show("manager", p2)}>
            <h2>{t("FB_SH01_manager")}</h2>
            <img src={p2} />
          </div>
          <div class="dossier" onClick={() => this.show("guard", p3)}>
            <h2>{t("FB_SH01_guard")}</h2>
            <img src={p3} />
          </div>
          <div class="dossier" onClick={() => this.show("taster", p4)}>
            <h2>{t("FB_SH01_taster")}</h2>
            <img src={p4} />
          </div>
          <div class="dossier" onClick={() => this.show("driver", p5)}>
            <h2>{t("FB_SH01_driver")}</h2>
            <img src={p5} />
          </div>
          <form>
            <input
              class="dader_knop"
              type="button"
              onClick={() => (window.location.href = "suspects")}
              value="Robotfoto's bekijken"
            />
          </form>
        </div>

        <div id="form" class="modal">
          <form class="modal-content animate">
            <div class="imgcontainer">
              <span
                onClick={() =>
                  (document.getElementById("form").style.display = "none")
                }
                class="close"
                title="Close Modal"
              >
                &times;
              </span>
              <img
                src="games/superhelden/imgs/dossier.png"
                alt="Avatar"
                class="avatar"
                id="avatar"
              />
            </div>

            <div class="container">
              <label for="psw" id="dossier">
                <b>
                  Wachtwoord nodig om te praten met de{" "}
                  {t("FB_SH01_" + this.state.person)}
                </b>
              </label>
              <input
                type="text"
                id="password"
                placeholder="Wachtwoord dossier"
                name="psw"
                autocomplete="off"
                required
              />

              <button type="button" onClick={this.chat}>
                Contacteer Getuige
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation(["games"])(WitnessOverview);
