import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import CheckList from "../../games/Detective/checklist.js";
import Mail from "../../games/Detective/mail.js";

class DetectiveGame extends React.Component {
  componentDidMount() {
    document.title = "Detective Speurtocht - Festiviti";
  }
  render() {
    if (this.props.match.params.phase == "checklist")
      return <CheckList background={backgroundIMG} />;
    if (this.props.match.params.phase == "mail")
      return <Mail background={backgroundIMG} next="checklist" />;
    if (this.props.match.params.phase == "play")
      return <Start background={backgroundIMG} next="mail" />;
    if (this.props.match.params.phase == "test")
      return <Test background={backgroundIMG} next="mail" />;
    else return <Redirect to="/overview" />;
  }
}

export default withRouter(DetectiveGame);
