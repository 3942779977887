import React from "react";
import ReactDOM from "react-dom";
import tv from "../ER_Boyard/imgs/tv.png";
import lock from "../ER_Boyard/imgs/doorlock.png";
import steel from "../ER_Boyard/imgs/oldsteel.jpeg";
import call from "../ER_Boyard/imgs/call.gif";
import calling from "../ER_Boyard/imgs/calling.gif";
import callaudio from "../ER_Boyard/audio/robin_3.mp3";
import styles from "../ER_Boyard/rooms.css";
import {
  Layout,
  Menu,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import { withTranslation } from "react-i18next";

class DoorCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: new Array(36).fill(false),
      audio: true,
    };
    this.play = this.play.bind(this);
  }
  play() {
    if (!this.state.playing) {
      var audio = document.getElementById("call");
      this.setState({ playing: true });
      audio.play();
      setTimeout(() => {
        var audio = document.getElementById("call");
        audio.pause();
        this.setState({ audio: false });
      }, 16000);
    }
  }
  match(arr1) {
    const arr2 = [
      true,
      false,
      false,
      false,
      false,
      true,
      false,
      true,
      false,
      false,
      true,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
      false,
      true,
      false,
      false,
      true,
      false,
      true,
      false,
      false,
      false,
      false,
      true,
    ];
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;
  }
  switchbutton(i) {
    var b = this.state.buttons;
    b[i] = !b[i];
    this.setState({ buttons: b });
    if (this.match(b)) window.location.href = "alarm";
  }
  render() {
    const {t}=this.props;
    const buttonstyle = { width: 50, height: 50, margin: 5, float: "left" };
    return (
      <div className="Boyard_Door" style={{ height: "100%", margin: 0 }}>
        <audio src={callaudio} id="call"></audio>
        <Modal
          title={t("robin-phone")}
          visible={this.state.audio}
          width={570}
          footer={[]}
        >
          {this.state.playing ? (
            <img src={calling} style={{ width: "70%", marginLeft: "15%" }} />
          ) : (
            <img
              src={call}
              style={{ width: "70%", marginLeft: "15%" }}
              onClick={this.play}
            />
          )}
        </Modal>

        <div style={{ width: "100%", height: "100%", textALign: "center" }}>
          <img
            src={lock}
            style={{ margin: "auto", height: 600, display: "block" }}
          />
          <div
            style={{
              width: 250,
              position: "absolute",
              top: 270,
              left: "50%",
              marginLeft: -111,
            }}
          >
            {this.state.buttons.map((state, index) => {
              return (
                <div
                  onClick={() => this.switchbutton(index)}
                  style={{
                    width: 30,
                    height: 30,
                    float: "left",
                    margin: 3,
                    backgroundColor: state ? "blue" : "black",
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(DoorCode);
