import React from "react";
import ReactDOM from "react-dom";
import plan from "../ER_Boyard/imgs/plan.jpg";
import call from "../ER_Boyard/imgs/call.gif";
import calling from "../ER_Boyard/imgs/calling.gif";
import callaudio from "../ER_Boyard/audio/robin_2.mp3";
import styles from "../ER_Boyard/rooms.css";
import {
  Layout,
  Menu,
  Radio,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import { withTranslation } from "react-i18next";

class Rooms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      audio: true,
      selection: "1",
      playing: false,
    };
    this.check = this.check.bind(this);
    this.play = this.play.bind(this);
  }
  check() {
    this.setState({ modal: false });
    if (this.state.selection === "3") window.location.href = "door";
  }
  play() {
    if (!this.state.playing) {
      var audio = document.getElementById("call");
      this.setState({ playing: true });
      audio.play();
      setTimeout(() => {
        var audio = document.getElementById("call");
        audio.pause();
        this.setState({ audio: false });
      }, 18000);
    }
  }
  render() {
    const {t}=this.props;
    return (
      <div
        className="Boyard_Rooms"
        style={{ height: "100%", margin: 0, backgroundColor: "#999" }}
      >
        <audio src={callaudio} id="call"></audio>
        <p id="time"></p>
        <Button id="contact" onClick={() => this.setState({ modal: true })}>
          {t("robin-contact")}
        </Button>
        <div id="plan"></div>
        <Modal
          title={t("robin-phone")}
          visible={this.state.audio}
          width={570}
          footer={[]}
        >
          {this.state.playing ? (
            <img src={calling} style={{ width: "70%", marginLeft: "15%" }} />
          ) : (
            <img
              src={call}
              style={{ width: "70%", marginLeft: "15%" }}
              onClick={this.play}
            />
          )}
        </Modal>

        <Modal
          title={t("robin-info")}
          visible={this.state.modal}
          onCancel={() => this.setState({ modal: false })}
          width={690}
          footer={[
            <Button
              key="info"
              style={{
                background: "#F0730D",
                color: "white",
                borderColor: "#F0730D",
              }}
              onClick={this.check}
            >
              {t("robin-send")}
            </Button>,
          ]}
        >
          <p style={{ textAlign: "center", fontWeight: 600, color: "#F0730D" }}>
          {t("robin-warning")}
          </p>
          <Radio.Group
            value={this.state.selection}
            onChange={(e) => this.setState({ selection: e.target.value })}
          >
            <Radio.Button value="1">{t("robin-room")} 1</Radio.Button>
            <Radio.Button value="2">{t("robin-room")} 2</Radio.Button>
            <Radio.Button value="3">{t("robin-room")} 3</Radio.Button>
            <Radio.Button value="4">{t("robin-room")} 4</Radio.Button>
            <Radio.Button value="5">{t("robin-room")} 5</Radio.Button>
            <Radio.Button value="6">{t("robin-room")} 6</Radio.Button>
          </Radio.Group>
        </Modal>
      </div>
    );
  }
}

export default withTranslation(['games'])(Rooms);
