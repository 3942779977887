import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Astro/maze.jsx";
import styles from "../ER_Astro/maze.css";
import grid from "../ER_Astro/imgs/spacegrid.jpg";
import rocket from "../ER_Astro/imgs/rocket.png";
import earth from "../ER_Astro/imgs/aarde.png";
import up from "../ER_Astro/imgs/arrowU.png";
import down from "../ER_Astro/imgs/arrowD.png";
import left from "../ER_Astro/imgs/arrowL.png";
import right from "../ER_Astro/imgs/arrowR.png";
import spacemusic from "../ER_Astro/audio/epic_space.mp3";
import { withTranslation } from "react-i18next";

class Access extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    javascript("doors");
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Astronaut_Maze">
        <div id="timefixed">
          <p>{t("astro-alarm")}</p>
          <p id="time">00:00</p>
        </div>
        <img id="back" src={grid} />
        <img id="rocket" src={rocket} />
        <img id="aarde" src={earth} />
        <img id="arrowu" src={up} />
        <img id="arrowd" src={down} />
        <img id="arrowr" src={right} />
        <img id="arrowl" src={left} />
        <audio src={spacemusic} id="music" loop></audio>
      </div>
    );
  }
}

export default withTranslation(['games'])(Access);
