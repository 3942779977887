import React from "react";
import ReactDOM from "react-dom";
import ReactSlider from "react-slider";
import styles from "../ER_Astro/engine.css";
import battery from "../ER_Astro/imgs/battery.png";
import candy from "../ER_Astro/imgs/candy.png";
import button from "../ER_Astro/imgs/button.png";
import steel from "../ER_Astro/imgs/steel.png";
import { Slider, Row, Col } from "antd";
import { withTranslation } from "react-i18next";

class Egine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      s5: 0,
    };
  }
  componentDidMount() {
    //javascript("end");
  }
  checkStart() {
    if (
      this.state.s1 == 4 &&
      this.state.s2 == 2 &&
      this.state.s3 == 34 &&
      this.state.s4 == 29 &&
      this.state.s5 == 15
    ) {
      window.location.href = "/game/FB_ER02/maze";
    }
  }
  render() {
    const colstyle = {
      padding: 20,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    };
    const rowstyle = {
      padding: 30,
      height: "50%",
    };
    const {t}=this.props;
    return (
      <div id="window" style={{ height: "100%" }} className="Astronaut_Engine">
        <p id="time"></p>
        <Row style={{ height: "50%" }}>
          <Col span={16} style={{ height: "100%" }}>
            <Row style={rowstyle}>
              <img style={{ height: "60%", margin: "auto" }} src={battery} />
              <div
                style={{
                  width: "70%",
                  height: 45,
                  padding: 5,
                  backgroundColor: "grey",
                  marginTop: 30,
                  marginLeft: 30,
                }}
              >
                {" "}
                <Slider
                  onAfterChange={(value) => this.setState({ s1: value })}
                  style={{ width: "90%" }}
                  defaultValue={5}
                  max={10}
                  tooltipVisible
                />
              </div>
            </Row>
            <Row style={rowstyle}>
              <img style={{ height: "60%", margin: "auto" }} src={candy} />
              <div
                style={{
                  width: "70%",
                  height: 45,
                  padding: 5,
                  backgroundColor: "grey",
                  marginTop: 30,
                  marginLeft: 30,
                }}
              >
                {" "}
                <Slider
                  onAfterChange={(value) => this.setState({ s2: value })}
                  style={{ width: "90%" }}
                  defaultValue={5}
                  max={10}
                  tooltipVisible
                />
              </div>
            </Row>
          </Col>
          <Col
            span={8}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={button}
              style={{ height: 200 }}
              onClick={() => this.checkStart()}
            />
          </Col>
        </Row>
        <Row style={{ height: "50%" }}>
          <h2 className="title">{t("astro-gas")}</h2>
          <Col span={8} style={colstyle}>
            <div style={{ width: 50, height: "90%" }}>
              <p className="subtitle">{t("astro-sweet")}</p>
              <div
                style={{ height: "80%", padding: 5, backgroundColor: "grey" }}
              >
                <Slider
                  onAfterChange={(value) => this.setState({ s3: value })}
                  style={{ height: "80%", margin: "auto" }}
                  vertical
                  defaultValue={30}
                  max={50}
                  tooltipVisible
                />
              </div>
            </div>
          </Col>
          <Col span={8} style={colstyle}>
            <div style={{ width: 50, height: "90%" }}>
              <p className="subtitle">{t("astro-neutral")}</p>
              <div
                style={{ height: "80%", padding: 5, backgroundColor: "grey" }}
              >
                <Slider
                  onAfterChange={(value) => this.setState({ s4: value })}
                  style={{ height: "80%", margin: "auto" }}
                  vertical
                  defaultValue={30}
                  max={50}
                  tooltipVisible
                />
              </div>
            </div>
          </Col>
          <Col span={8} style={colstyle}>
            <div style={{ width: 50, height: "90%" }}>
              <p className="subtitle">{t("astro-salt")}</p>
              <div
                style={{ height: "80%", padding: 5, backgroundColor: "grey" }}
              >
                <Slider
                  onAfterChange={(value) => this.setState({ s5: value })}
                  style={{ height: "80%", margin: "auto" }}
                  vertical
                  defaultValue={30}
                  max={50}
                  tooltipVisible
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation(['games'])(Egine);
