import React from "react";
import ReactDOM from "react-dom";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/access.css";
import service from "../FB_Hero/imgs/secret_service.jpeg";
import slogo from "../FB_Hero/imgs/secret_logo.png";

class Access extends React.Component {
  check() {
    if (document.getElementById("wachtwoord").value == "superman") {
      document.getElementById("wachtwoord").value = "";
      window.location.href = "welcome";
    }
  }
  render() {
    return (
      <div className="Hero_Access">
        <div className="demo">
          <div className="login">
            <div className="login__check"></div>
            <div className="login__form">
              <p id="error_message">Foute toegangscode.</p>
              <div className="login__row">
                <img src={slogo} height={25} />
                <input
                  type="text"
                  id="naam"
                  className="login__input name"
                  placeholder="Naam Hoofdkwartier"
                />
              </div>
              <div className="login__row">
                <img src={slogo} height={25} />
                <input
                  type="text"
                  id="leider"
                  className="login__input name"
                  placeholder="Naam Leider"
                />

                <div className="login__row">
                  <img src={slogo} height={25} />
                  <input
                    type="password"
                    id="wachtwoord"
                    className="login__input name"
                    placeholder="Geheim Wachtwoord"
                  />
                  <button
                    onClick={() => this.check()}
                    type="button"
                    className="loginsubmit"
                  >
                    Aanmelden
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Access;
