import $ from "jquery";
import styleFix from "stylefix.js";
import { XDomainRequest } from "jquery-ajax-transport-xdomainrequest";

function javascript(next_code) {
  /**
   * PrefixFree
   */
  (function (root) {
    if (!window.styleFix || !window.getComputedStyle) {
      return;
    }

    // Private helper
    function fix(what, before, after, replacement, css) {
      what = self[what];

      if (what.length) {
        var regex = RegExp(before + "(" + what.join("|") + ")" + after, "gi");

        css = css.replace(regex, replacement);
      }

      return css;
    }

    var self = (window.PrefixFree = {
      prefixCSS: function (css, raw, element) {
        var prefix = self.prefix;

        // Gradient angles hotfix
        if (self.functions.indexOf("linear-gradient") > -1) {
          // Gradients are supported with a prefix, convert angles to legacy
          css = css.replace(
            /(\s|:|,)(repeating-)?linear-gradient\(\s*(-?\d*\.?\d*)deg/gi,
            function ($0, delim, repeating, deg) {
              return (
                delim +
                (repeating || "") +
                "linear-gradient(" +
                (90 - deg) +
                "deg"
              );
            }
          );
        }

        css = fix(
          "functions",
          "(\\s|:|,)",
          "\\s*\\(",
          "$1" + prefix + "$2(",
          css
        );
        css = fix(
          "keywords",
          "(\\s|:)",
          "(\\s|;|\\}|$)",
          "$1" + prefix + "$2$3",
          css
        );
        css = fix(
          "properties",
          "(^|\\{|\\s|;)",
          "\\s*:",
          "$1" + prefix + "$2:",
          css
        );

        // Prefix properties *inside* values (issue #8)
        if (self.properties.length) {
          var regex = RegExp(
            "\\b(" + self.properties.join("|") + ")(?!:)",
            "gi"
          );

          css = fix(
            "valueProperties",
            "\\b",
            ":(.+?);",
            function ($0) {
              return $0.replace(regex, prefix + "$1");
            },
            css
          );
        }

        if (raw) {
          css = fix("selectors", "", "\\b", self.prefixSelector, css);
          css = fix("atrules", "@", "\\b", "@" + prefix + "$1", css);
        }

        // Fix double prefixing
        css = css.replace(RegExp("-" + prefix, "g"), "-");

        // Prefix wildcard
        css = css.replace(/-\*-(?=[a-z]+)/gi, self.prefix);

        return css;
      },

      property: function (property) {
        return (
          (self.properties.indexOf(property) ? self.prefix : "") + property
        );
      },

      value: function (value, property) {
        value = fix(
          "functions",
          "(^|\\s|,)",
          "\\s*\\(",
          "$1" + self.prefix + "$2(",
          value
        );
        value = fix(
          "keywords",
          "(^|\\s)",
          "(\\s|$)",
          "$1" + self.prefix + "$2$3",
          value
        );

        // TODO properties inside values

        return value;
      },

      // Warning: Prefixes no matter what, even if the selector is supported prefix-less
      prefixSelector: function (selector) {
        return selector.replace(/^:{1,2}/, function ($0) {
          return $0 + self.prefix;
        });
      },

      // Warning: Prefixes no matter what, even if the property is supported prefix-less
      prefixProperty: function (property, camelCase) {
        var prefixed = self.prefix + property;

        return camelCase ? styleFix.camelCase(prefixed) : prefixed;
      },
    });

    /**************************************
     * Properties
     **************************************/
    (function () {
      var prefixes = {},
        properties = [],
        shorthands = {},
        style = getComputedStyle(document.documentElement, null),
        dummy = document.createElement("div").style;

      // Why are we doing this instead of iterating over properties in a .style object? Cause Webkit won't iterate over those.
      var iterate = function (property) {
          if (property.charAt(0) === "-") {
            properties.push(property);

            var parts = property.split("-"),
              prefix = parts[1];

            // Count prefix uses
            prefixes[prefix] = ++prefixes[prefix] || 1;

            // This helps determining shorthands
            while (parts.length > 3) {
              parts.pop();

              var shorthand = parts.join("-");

              if (
                supported(shorthand) &&
                properties.indexOf(shorthand) === -1
              ) {
                properties.push(shorthand);
              }
            }
          }
        },
        supported = function (property) {
          return styleFix.camelCase(property) in dummy;
        };

      // Some browsers have numerical indices for the properties, some don't
      if (style.length > 0) {
        for (var i = 0; i < style.length; i++) {
          iterate(style[i]);
        }
      } else {
        for (var property in style) {
          iterate(styleFix.deCamelCase(property));
        }
      }

      // Find most frequently used prefix
      var highest = { uses: 0 };
      for (var prefix in prefixes) {
        var uses = prefixes[prefix];

        if (highest.uses < uses) {
          highest = { prefix: prefix, uses: uses };
        }
      }

      self.prefix = "-" + highest.prefix + "-";
      self.Prefix = styleFix.camelCase(self.prefix);

      self.properties = [];

      // Get properties ONLY supported with a prefix
      for (var i = 0; i < properties.length; i++) {
        var property = properties[i];

        if (property.indexOf(self.prefix) === 0) {
          // we might have multiple prefixes, like Opera
          var unprefixed = property.slice(self.prefix.length);

          if (!supported(unprefixed)) {
            self.properties.push(unprefixed);
          }
        }
      }

      // IE fix
      if (
        self.Prefix == "Ms" &&
        !("transform" in dummy) &&
        !("MsTransform" in dummy) &&
        "msTransform" in dummy
      ) {
        self.properties.push("transform", "transform-origin");
      }

      self.properties.sort();
    })();

    /**************************************
     * Values
     **************************************/
    (function () {
      // Values that might need prefixing
      var functions = {
        "linear-gradient": {
          property: "backgroundImage",
          params: "red, teal",
        },
        calc: {
          property: "width",
          params: "1px + 5%",
        },
        element: {
          property: "backgroundImage",
          params: "#foo",
        },
        "cross-fade": {
          property: "backgroundImage",
          params: "url(a.png), url(b.png), 50%",
        },
      };

      functions["repeating-linear-gradient"] =
        functions["repeating-radial-gradient"] =
        functions["radial-gradient"] =
          functions["linear-gradient"];

      // Note: The properties assigned are just to *test* support.
      // The keywords will be prefixed everywhere.
      var keywords = {
        initial: "color",
        "zoom-in": "cursor",
        "zoom-out": "cursor",
        box: "display",
        flexbox: "display",
        "inline-flexbox": "display",
        flex: "display",
        "inline-flex": "display",
        grid: "display",
        "inline-grid": "display",
        "min-content": "width",
      };

      self.functions = [];
      self.keywords = [];

      var style = document.createElement("div").style;

      function supported(value, property) {
        style[property] = "";
        style[property] = value;

        return !!style[property];
      }

      for (var func in functions) {
        var test = functions[func],
          property = test.property,
          value = func + "(" + test.params + ")";

        if (
          !supported(value, property) &&
          supported(self.prefix + value, property)
        ) {
          // It's supported, but with a prefix
          self.functions.push(func);
        }
      }

      for (var keyword in keywords) {
        var property = keywords[keyword];

        if (
          !supported(keyword, property) &&
          supported(self.prefix + keyword, property)
        ) {
          // It's supported, but with a prefix
          self.keywords.push(keyword);
        }
      }
    })();

    /**************************************
     * Selectors and @-rules
     **************************************/
    (function () {
      var selectors = {
          ":read-only": null,
          ":read-write": null,
          ":any-link": null,
          "::selection": null,
        },
        atrules = {
          keyframes: "name",
          viewport: null,
          document: 'regexp(".")',
        };

      self.selectors = [];
      self.atrules = [];

      var style = root.appendChild(document.createElement("style"));

      function supported(selector) {
        style.textContent = selector + "{}"; // Safari 4 has issues with style.innerHTML

        return !!style.sheet.cssRules.length;
      }

      for (var selector in selectors) {
        var test =
          selector +
          (selectors[selector] ? "(" + selectors[selector] + ")" : "");

        if (!supported(test) && supported(self.prefixSelector(test))) {
          self.selectors.push(selector);
        }
      }

      for (var atrule in atrules) {
        var test = atrule + " " + (atrules[atrule] || "");

        if (!supported("@" + test) && supported("@" + self.prefix + test)) {
          self.atrules.push(atrule);
        }
      }

      root.removeChild(style);
    })();

    // Properties that accept properties as their value
    self.valueProperties = ["transition", "transition-property"];

    // Add class for current prefix
    root.className += " " + self.prefix;

    styleFix.register(self.prefixCSS);
  })(document.documentElement);
  var offset = 500;
  var timeouts = [];

  function long() {
    timeouts.push(
      setTimeout(function () {
        var img = document.getElementById("hide");
        img.style.visibility = "hidden";
        var audio = document.getElementById("long");
        audio.play();
      }, offset)
    );
    timeouts.push(
      setTimeout(function () {
        var img = document.getElementById("hide");
        img.style.visibility = "visible";
      }, offset + 800)
    );
    offset = offset + 800 + 500;
  }
  function short() {
    timeouts.push(
      setTimeout(function () {
        var img = document.getElementById("hide");
        img.style.visibility = "hidden";
        var audio = document.getElementById("short");
        audio.play();
      }, offset)
    );
    timeouts.push(
      setTimeout(function () {
        var img = document.getElementById("hide");
        img.style.visibility = "visible";
      }, offset + 300)
    );
    offset = offset + 300 + 500;
  }
  function timedstop() {
    timeouts.push(
      setTimeout(function () {
        playing = false;
        document.getElementById("morsebutton").innerHTML = "Start MORSE";
      }, offset)
    );
  }
  var playing = false;
  function start_morse_NL() {
    var audio = document.getElementById("intro_nl");
    audio.play();
    offset = 3000;
    long();
    short();
    long();
    offset = offset + 2000;
    short();
    long();
    short();
    short();
    offset = offset + 2000;
    long();
    long();
    long();
    offset = offset + 2000;
    short();
    long();
    long();
    short();
    offset = offset + 2000;
    short();
    short();
    short();
    long();
    long();
    offset = offset + 2000;
    long();
    short();
    short();
    long();
    offset = offset + 2000;
    long();
    long();
    long();
    offset = offset + 2000;
    short();
    long();
    long();
    short();
    offset = offset + 2000;
    long();
    short();
    short();
    offset = offset + 2000;
    short();
    offset = offset + 2000;
    short();
    short();
    long();
    offset = offset + 2000;
    short();
    long();
    short();
    timedstop();
  }
  function start_morse_DE() {
    var audio = document.getElementById("intro_de");
    audio.play();
    offset = 3000;
    long();
    short();
    long(); //k
    offset = offset + 2000;
    short();
    long();
    short();
    short(); //l
    offset = offset + 2000;
    long();
    long();
    long(); //o
    offset = offset + 2000;
    short();
    long();
    long();
    short(); //p
    offset = offset + 2000;
    short();
    short();
    long();
    short(); //f
    offset = offset + 2000;
    short(); //e
    offset = offset + 2000;
    short();
    short();
    short();
    long();
    long(); // 3
    offset = offset + 2000;
    long();
    short();
    short();
    long(); //X
    offset = offset + 2000;
    short();
    long(); //a
    offset = offset + 2000;
    long();
    short(); //n
    offset = offset + 2000;
    long();
    short();
    short(); //d
    offset = offset + 2000;
    short();
    short(); //i
    offset = offset + 2000;
    short(); //e
    offset = offset + 2000;
    long(); //t
    offset = offset + 2000;
    short();
    short();
    long(); //u
    offset = offset + 2000;
    short();
    long();
    short(); //r
    timedstop();
  }
  function stop_morse() {
    var img = document.getElementById("hide");
    img.style.visibility = "visible";
    for (var i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
    playing = false;
  }

  function start_morse() {
    console.log(playing);
    if (!playing) {
      playing = true;
      document.getElementById("morsebutton").innerHTML = "Stop MORSE";
      start_morse_NL();
    } else {
      stop_morse();
      document.getElementById("morsebutton").innerHTML = "Start MORSE";
    }
  }

  function login() {
    var user = document.getElementById("username");
    var pass = document.getElementById("pass");
    console.log(user);
    if (
      user.value.toUpperCase() == "AGENTX" &&
      pass.value.toUpperCase() == "UNDERCOVER"
    ) {
      user.value = "";
      pass.value = "";
      window.location.href = next_code;
    }
  }
  $("#morsebutton").click(function () {
    start_morse();
  });
  $("#loginbutton").click(function () {
    login();
  });
}
export default javascript;
