import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Agents/morse.jsx";
import b1 from "../ER_Agents/imgs/b1.png";
import b2 from "../ER_Agents/imgs/b2.png";
import short from "../ER_Agents/audio/short.mp3";
import long from "../ER_Agents/audio/long.mp3";
import intro_nl from "../ER_Agents/audio/morse_intro_nl.mp3";
import intro_de from "../ER_Agents/audio/morse_intro_de.mp3";
import styles from "../ER_Agents/morse.css";
import { withTranslation } from "react-i18next";

class Morse extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    javascript("alarm");
  }
  render() {
    const {t}= this.props;
    return (
      <div className="Agent_Morse">
        <audio src={intro_nl} id="intro_nl"></audio>
        <audio src={intro_de} id="intro_de"></audio>
        <audio src={long} id="long"></audio>
        <audio src={short} id="short"></audio>
        <p id="time"></p>

        <div id="logo">
          <h1 id="text1">
            <i> {t("agent-access")}</i>
          </h1>
        </div>
        <section className="stark-login">
          <form action="" method="">
            <div id="fade-box">
              <img src={b1} className="button" />
              <img src={b2} className="button" id="hide" />
              <input
                type="text"
                name="username"
                id="username"
                placeholder={t("agent-username")}
                required
              />
              <input type="text" placeholder={t("agent-pass")} id="pass" required />
              <button id="morsebutton" type="button">
                {t("agent-morse")}
              </button>
              <button type="button" id="loginbutton">
                {t("agent-login")}
              </button>
            </div>
          </form>
          <div className="hexagons"></div>
        </section>

        <div id="circle1">
          <div id="inner-cirlce1">
            <h2> </h2>
          </div>
        </div>

        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    );
  }
}

export default withTranslation(['games'])(Morse);
