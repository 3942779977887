import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Input, Button, Modal } from "antd";
import style from "../CHRSPECIAL/settings.css";
import g1 from "../CHRSPECIAL/imgs/g1.png";
import g2 from "../CHRSPECIAL/imgs/g2.png";
import g3 from "../CHRSPECIAL/imgs/g3.png";
import g4 from "../CHRSPECIAL/imgs/g4.png";
import g5 from "../CHRSPECIAL/imgs/g5.png";
import arrow from "../CHRSPECIAL/imgs/arrow.png";
import top from "../CHRSPECIAL/imgs/top.png";
import bottom from "../CHRSPECIAL/imgs/bottom.png";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.setText=this.setText.bind(this);
    this.save=this.save.bind(this);
    this.state = {
      saving:false,
      hints:["Laden...","Laden...","Laden...","Laden...","Laden..."],

    };
  }
  save() {
    this.setState({saving:true});
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({hints:this.state.hints}),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({saving:false});
      })
      .catch((error) => console.log(error));
  }
  componentDidMount() {
    console.log("mount");
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(info);
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      { method: "GET", headers: { Authorization: "Bearer " + user.token } }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.hints)
      this.setState({
        hints: result.hints,
      });
      else
    this.setState({
      hints: ["","","","",""],
    });
      });
    }
  setText(event,i){
    const hints=this.state.hints;
    hints[i]=event.target.value;
    this.setState({ hints: hints });
  }
  render() {
    return (
      <div className="CHRISTMAS">

      <a onClick={()=>window.location.href =this.props.back} id="back">Terug</a>
      <div className="hint">
      Hint/Raadsel 1<br/>
          <textarea id="Text1" cols="50" rows="2" value={this.state.hints[0]}
          onChange={(event)=>this.setText(event,0)}></textarea></div>
       <div className="hint">
      Hint/Raadsel 2<br/>
      <textarea id="Text2" cols="50" rows="2" value={this.state.hints[1]}
      onChange={(event)=>this.setText(event,1)}></textarea></div>
       <div className="hint">
      Hint/Raadsel 3<br/>
      <textarea id="Text3" cols="50" rows="2" value={this.state.hints[2]}
      onChange={(event)=>this.setText(event,2)}></textarea></div>
      <div className="hint">
      Hint/Raadsel 4<br/>
      <textarea id="Text4" cols="50" rows="2" value={this.state.hints[3]}
      onChange={(event)=>this.setText(event,3)}></textarea></div>
       <div className="hint">
      Hint/Raadsel 5<br/>
      <textarea id="Text5" cols="50" rows="2" value={this.state.hints[4]}
      onChange={(event)=>this.setText(event,4)}></textarea></div>
      <div className="bottom">  <a onClick={this.save} class="link">{this.state.saving?"Even Geduld...":"Opslaan"}</a></div>


      </div>

    )};
  }
export default Settings;
