import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Agents/secret.jsx";
import styles from "../ER_Agents/secret.css";
import { withTranslation } from "react-i18next";

class Secret extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    javascript("end");
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Agents_Secret">
        <div id="hintheader"></div>
        <p id="time"></p>
        <div id="center">
          <figure id="bridgeContainer">
            <canvas id="bridge"></canvas>
          </figure>
          <div className="container">
            <div className="container__item">
              <form className="form">
                <input
                  id="codewoord"
                  type="text"
                  className="form__field"
                  placeholder={t("agent-code")}
                />
                <button
                  type="button"
                  className="btn btn--primary btn--inside uppercase"
                  id="checkbutton"
                >
                  {t("agent-check")}
                </button>
              </form>
            </div>

            <div className="container__item container__item--bottom"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(Secret);
