import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Astro/phone.jsx";
import styles from "../ER_Astro/phone.css";
import call from "./audio/call.mp3";
import alien_NL from "./audio/alien_NL.mp3";
import alien_img from "./imgs/gerrit.png";
import { withTranslation } from "react-i18next";

class Phone extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    const {t}=this.props
    javascript(
      "engine",
      t("astro-alert"),
      t("astro-help"),
      t("astro-calling"),
      alien_img
    );
  }
  render() {
    return (
      <div id="window" className="Astronaut_Phone">
        <div className="pad">
          <div className="dial-pad">
            <div className="phoneString">
              <input className="text" disabled />
            </div>
            <div className="digits">
              <div className="dig number-dig" name="1">
                1<div className="sub-dig"></div>
              </div>
              <div className="dig number-dig" name="2">
                2<div className="sub-dig">ABC</div>
              </div>
              <div className="dig number-dig" name="3">
                3<div className="sub-dig">DEF</div>
              </div>
              <div className="dig number-dig" name="4">
                4<div className="sub-dig">GHI</div>
              </div>
              <div className="dig number-dig" name="5">
                5<div className="sub-dig">JKL</div>
              </div>
              <div className="dig number-dig" name="6">
                6<div className="sub-dig">MNO</div>
              </div>
              <div className="dig number-dig" name="7">
                7<div className="sub-dig">PQRS</div>
              </div>
              <div className="dig number-dig" name="8">
                8<div className="sub-dig">TUV</div>
              </div>
              <div className="dig number-dig" name="9">
                9<div className="sub-dig">WXYZ</div>
              </div>
              <div className="dig number-dig astrisk" name="*">
                *
              </div>
              <div className="dig number-dig pound" name="0">
                0
              </div>
              <div className="dig number-dig pound" name="#">
                #
              </div>
            </div>
            <div className="digits">
              <div className="dig addPerson action-dig"></div>
              <div className="dig-spacer"></div>
              <div className="dig goBack action-dig"></div>
            </div>
          </div>
          <div className="call-pad">
            <div className="pulsate">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div id="avatar" className="ca-avatar"></div>
            <div className="ca-name" id="name">
              Onbekend nummer
            </div>
            <div className="ca-number" id="number">
              123 456 789
            </div>
            <div className="ca-status" data-dots="...">
              Oproep
            </div>
          </div>

          <div className="call action-dig">
            <div className="call-change">
              <span></span>
            </div>
            <div className="call-icon"></div>
          </div>
        </div>
        <p id="timep">
          <span id="time"></span>
        </p>

        <audio src={call} id="oproep" loop></audio>
        <audio src={alien_NL} id="alien"></audio>
      </div>
    );
  }
}

export default withTranslation(['games'])(Phone);
