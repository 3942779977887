import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Input, Button, Modal } from "antd";
import { CirclePicker } from "react-color";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import corner1 from "../Detective/imgs/corner1.png";
import corner2 from "../Detective/imgs/corner2.png";
import send from "../Detective/imgs/send_email.gif";
import egstyleg from "../Detective/checklist.css";
import CanvasDraw from "react-canvas-draw";
const { confirm } = Modal;
const { TextArea } = Input;

class Mail extends React.Component {
  constructor(props) {
    super(props);
    this.showConfirm = this.showConfirm.bind(this);
    this.state = {
      drawing: false,
      sending: false,
      mail: false,
      color: "#000000",
    };
  }
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  showConfirm() {
    confirm({
      title: "Nieuwe Email ontvangen!",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          Van: Detective James
          <br />
          Onderwerp: Helpers Gezocht!
        </p>
      ),
      okText: "BEKIJKEN",
      cancelButtonProps: {
        style: { display: "none" },
      },
      onOk: () => {
        this.setState({ sending: false, mail: true });
      },
    });
  }
  send() {
    this.setState({ sending: true });
    setTimeout(this.showConfirm, 3000);
  }
  render() {
    return (
      <div id="window" className="MINI2_Mail">
        <Modal
          title="Detective James zoekt hulp"
          visible={this.state.mail}
          width={750}
          footer={null}
        >
          <p
            style={{ fontFamily: "Permanent Marker", fontSize: 20 }}
            className="writing"
          >
            Beste toekomstige helper,
            <br />
            <br />
            Ik heb met veel interesse je email gelezen. Als detective heb ik wel
            hoge verwachtingen van een nieuwe helper:
            <br />
            Een nieuwe detective moet goed kunnen zoeken en goede ogen hebben.
            Daarom heb ik hier en daar wat raadsels en opdrachten verstopt.
            Hopelijk worden deze allemaal
            gevonden! Elk raadsel draait rond een eigenschap die ik van
            een goede detective verwacht.
             <br /><br />
            Is een opdracht geslaagd? Vink deze dan af
            van mijn “detective eisen-lijst”.
            <br />
            <br />
            <br />
            Veel succes!
            <br />
            <Button href="checklist" style={{ color: "black", fontSize: 20 }}>
              Bekijk hier de eisen-lijst
            </Button>
          </p>
        </Modal>
        <Modal
          title="Email versturen..."
          visible={this.state.sending}
          footer={null}
        >
          <img style={{ width: 250, marginLeft: 100 }} src={send} />
        </Modal>
        <h1 style={{ color: "white", paddingLeft: 20 }}>Contactformulier</h1>
        <img
          src={corner2}
          style={{ position: "absolute", top: 0, right: 0, width: 250 }}
        />
        <img
          src={corner1}
          style={{ position: "absolute", bottom: 0, left: 0, width: 250 }}
        />
        <Col span={14} offset={5}>
          <Row>
            <Col span={12}>
              <Row>
                <p style={{ marginBottom: 5 }}>
                  <b>Aan: </b>help@detective_james.com
                </p>
                <p style={{ marginBottom: 5 }}>
                  <b>Onderwerp:</b>"Detective hulp gezocht"
                </p>
              </Row>
              <Row>
                <Button
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.setState({ drawing: !this.state.drawing });
                  }}
                >
                  {!this.state.drawing
                    ? "Tekening Bijvoegen"
                    : "Tekst Schrijven"}
                </Button>
              </Row>
            </Col>
            <Col span={12}>
              {this.state.drawing && (
                <CirclePicker
                  color={this.state.color}
                  onChangeComplete={this.handleChangeComplete}
                />
              )}
            </Col>
          </Row>
          <Row>
            {this.state.drawing ? (
              <CanvasDraw
                brushRadius={5}
                brushColor={this.state.color}
                canvasWidth={800}
                canvasHeight={330}
              />
            ) : (
              <TextArea rows={14} placeholder="Beste James," />
            )}
          </Row>
          <Row>
            <Button
              onClick={() => this.send()}
              type="primary"
              style={{ margin: 10 }}
            >
              Verzenden
            </Button>
          </Row>
        </Col>
      </div>
    );
  }
}

export default Mail;
