import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Agents/codepad.jsx";
import styles from "../ER_Agents/codepad.css";
import { withTranslation } from "react-i18next";

class CodePad extends React.Component {
  constructor(props) {
    super(props);}

  componentDidMount() {
    javascript("buttons");
  }
  render() {
    const {t}= this.props;
    return (
      <div className="Agents_Codepad">
        <div id="pincode">
          <div className="table">
            <div className="cell">
              <div id="anleitung">
                <p id="time">60:00</p>
                <p id="text1">
                  <strong>{t("agent-codepad")}</strong>
                </p>
              </div>

              <div id="fields">
                <div className="grid">
                  <div className="grid__col grid__col--1-of-4 numberfield">
                    <span></span>
                  </div>
                  <div className="grid__col grid__col--1-of-4 numberfield">
                    <span></span>
                  </div>
                  <div className="grid__col grid__col--1-of-4 numberfield">
                    <span></span>
                  </div>
                  <div className="grid__col grid__col--1-of-4 numberfield">
                    <span></span>
                  </div>
                </div>
              </div>

              <div id="numbers">
                <div className="grid">
                  <div className="grid__col grid__col--1-of-3">
                    <button>1</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>2</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>3</button>
                  </div>

                  <div className="grid__col grid__col--1-of-3">
                    <button>4</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>5</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>6</button>
                  </div>

                  <div className="grid__col grid__col--1-of-3">
                    <button>7</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>8</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>9</button>
                  </div>

                  <div className="grid__col grid__col--1-of-3"></div>
                  <div className="grid__col grid__col--1-of-3">
                    <button>0</button>
                  </div>
                  <div className="grid__col grid__col--1-of-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(CodePad);
