// src/i18n.js
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const pref=localStorage.getItem('lang-pref')
console.log(pref);
i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: pref,
    ns: ['games', 'translation'],
    fallbackLng: "nl",
    debug: true,
    whitelist:["nl","fr"],
    backend: {
      // load from i18next-gitbook repo
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      crossDomain: true,
    },
  });
export default i18n;
