import styles from "../styles/main.css";
import React, { Component, ScrollView, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Card,
  Skeleton,
  Avatar,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import fotochallenge from "../imgs/fotochallenge.jpg";
import { withTranslation, useTranslation } from "react-i18next";

function sendmail(email, setLoading, setMail) {
  if (/^.+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(process.env.REACT_APP_BACKEND + "/api/referral?email=" + email, {
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setLoading(false);
        setMail("Verstuurd!");
      });
  } else {
    console.log("INVALID");
    setLoading(false);
  }
}

function Reductions(props) {
  const [sending, setSending] = useState(false);
  const [mail, setMail] = useState("");
  const numberstyle = {
    fontSize: 25,
    color: "#F0730D",
    fontWeight: 600,
    marginRight: 10,
  };
  const accent = { fontSize: 18, color: "#F0730D", fontWeight: 600 };
  const {t}= useTranslation();
  return (
    <div
      style={{ width: 700, display: "block", margin: "auto", paddingTop: 70 }}
    >
      <Card
        title={t("challenge-title")}
        style={{ width: 650, marginBottom: 30, fontSize: 16 }}
        headStyle={{ backgroundColor: "#0EAD81", color: "white", fontSize: 22 }}
        cover={
          <img src={fotochallenge} style={{ width: 450, marginLeft: 100 }} />
        }
      >
        <p>
        {t("challenge-desc2")}
        </p>
        <p style={{ paddingLeft: 30 }}>
          <span style={numberstyle}>1.</span>{t("challenge-step1-1")+" "}<span style={accent}>
          {t("challenge-step1-2")}</span> {" "+t("challenge-step1-3")}
          <br />
          <span style={numberstyle}>2.</span>{t("challenge-step2-1")+" "}
          <span style={accent}>{t("challenge-step2-2")}</span> {" "+t("challenge-step2-3")}
          <br />
          <span style={numberstyle}>3.</span>{t("challenge-step3-1")+" "}
          <span style={accent}>{t("challenge-step3-2")}</span> {" "+t("challenge-step3-3")}
        </p>
      </Card>
      <Card
        ref={props.friendRef}
        title={t("free-header")}
        id="friends"
        style={{ width: 650, fontSize: 16 }}
        headStyle={{ backgroundColor: "#0EAD81", color: "white", fontSize: 22 }}
      >
        <p>
        {t("free-intro")+" "}
          <strong style={{ color: "#F0730D" }}>{t("free-reduction")} </strong>
        </p>
        <p>
        {t("free-desc")+" "}
          <strong style={{ color: "#F0730D" }}> {t("free-desc2")}</strong>
        </p>
        <p style={{ textAlign: "center", margin: 0 }}>
        {"  "+t("free-code")}
          <strong style={{ color: "#F0730D", fontSize: 20 }}>
            {"  "+props.code}
          </strong>
        </p>
        <p style={{ color: "#0EAD81", textAlign: "center" }}>
        {t("free-score").replace('FF',props.friends)}
        </p>
        <center>
          <Input
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            style={{ width: 300, margin: 30 }}
          ></Input>
          <Button
            onClick={() => {
              setSending(true);
              sendmail(mail, setSending, setMail);
            }}
            loading={sending}
            style={{
              marginLeft: 20,
              background: "#0EAD81",
              borderColor: "#0EAD81",
              color: "white",
              fontWeight: 600,
            }}
          >
          {t("free-invite")}
          </Button>
        </center>
      </Card>
    </div>
  );
}
export default withTranslation()(Reductions);
