import React from "react";
import ReactDOM from "react-dom";
import javascript from "../ER_Labo/labo_codepad.jsx";
import styles from "../ER_Labo/labo_codepad.css";
import { withTranslation } from "react-i18next";

class CodePad extends React.Component {
  constructor(props) {
    super(props);}
  componentDidMount() {
    javascript("menu");
  }
  render() {
      const {t}=this.props;
    return (
      <div className="Labo_Codepad">
        <div id="pincode">
          <div className="table">
            <div className="cell">
              <div id="anleitung">
                <p id="time">60:00</p>
                <p id="text1">
                  <strong>{t("labo-codepad")}</strong>
                </p>
              </div>

              <div id="l_fields">
                <div className="l_grid">
                  <div className="l_grid__col l_grid__col--1-of-5 l_numberfield">
                    <span></span>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-5 l_numberfield">
                    <span></span>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-5 l_numberfield">
                    <span></span>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-5 l_numberfield">
                    <span></span>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-5 l_numberfield">
                    <span></span>
                  </div>
                </div>
              </div>

              <div id="l_numbers">
                <div className="l_grid">
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>L</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>Y</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>K</button>
                  </div>

                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>Y</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>C</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>O</button>
                  </div>

                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>A</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>R</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>B</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                    <button>#</button>
                  </div>
                  <div className="l_grid__col l_grid__col--1-of-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(CodePad);
