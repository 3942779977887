import React, { Component } from "react";
import key from "../imgs/key.svg";
import mail from "../imgs/mail.svg";
import logo from "../imgs/logo.png";
import background from "../imgs/background.jpg";
import styles from "../styles/login.css";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { spinner, remove_spinner, done } from "../login_animation.js";
import i18n from "../i18n.js";
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';


function changelang(l){
  localStorage.setItem('lang-pref', l);
  i18n.changeLanguage(l)
}
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a onClick={()=>changelang("nl")}>Nederlands</a>
    </Menu.Item>
  </Menu>
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordernumber: "",
      email: "",
      error_message: "",
      show_error: false,
    };
  }
  handleChange(event, key) {
    if (key == "ordernumber")
      this.setState({ ordernumber: event.target.value });
    if (key == "email") this.setState({ email: event.target.value });
  }
  login1(e) {
    spinner(e);
  }
  login2(e) {
    spinner(e);
  }
  handleClick(e) {
    const { t } = this.props;
    if (this.state.ordernumber != "" && this.state.email != "") {
      spinner(e);
      console.log(process.env.REACT_APP_BACKEND);
      fetch(process.env.REACT_APP_BACKEND + "/api/login", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " + btoa(this.state.ordernumber.toLowerCase().trim() + ":" + this.state.email.toLowerCase().trim()),
        },
      })
        .then((response) => response.json())
        .then((user) => {
          localStorage.setItem("user", JSON.stringify(user));
          done(e);
        })
        .catch((error) => {
          this.setState({
            error_message: t("login-wrong"),
          });
          remove_spinner();
          this.setState({ show_error: true });
        });
    }
  }
  render() {

    const { t } = this.props;
    return (
      <div
        className="cont"
        style={{ backgroundImage: "url(" + background + ")" }} >




        <div className="demo">
          <div className="login">
            <div className="login__check">
              <img className="logo" src={logo}></img>
            </div>
            <div className="login__form">
              <p
                id="error_message"
                style={{
                  visibility: this.state.show_error ? "visible" : "invisible",
                }}
              >
                {this.state.error_message}
              </p>
              <div className="login__row">
                <object type="image/svg+xml" data={key} height="20"></object>
                <input
                  type="text"
                  id="number"
                  className="login__input"
                  placeholder={t("ordernumber")}
                  value={this.state.ordernumber}
                  onChange={(e) => {
                    this.handleChange(e, "ordernumber");
                  }}
                />
              </div>

              <div className="login__row">
                <object type="image/svg+xml" data={mail} height="20"></object>
                <input
                  type="text"
                  id="email"
                  className="login__input"
                  placeholder={t("email")}
                  value={this.state.email}
                  onChange={(e) => {
                    this.handleChange(e, "email");
                  }}
                />
              </div>
              <button
                type="button"
                className="login__submit"
                onClick={(event) => this.handleClick(event)}
              >
                {t("start")}
              </button>
              <p className="login__signup">
                {t("login-info")}
                <br />{" "}
                <a style={{ color: "#F0730D" }} href="http://www.festiviti.eu">
                  {t("more-info")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Login));
