import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../styles/main.css";

class Action extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="ACTIE">
        <img src={t("refer-img")} />
        <p className="center desc">
          <span id="actietitle">{t("refer-title")}</span>
          {t("refer-desc1")}
          <br /> {t("refer-desc2")}{" "}
          <span className="accent">{t("refer-desc3")}</span>.<br />
          <br /> {t("refer-desc4")}{" "}
          <span className="accent">{t("refer-desc5")}</span> {t("refer-desc6")}
          <br /> {t("refer-desc7")}
        </p>
        <div id="actioninfo">
          <a
            className="actiebutton center"
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https://festivitibox.com"
          >
            {t("refer-share")}
          </a>
          <div id="invite">
            <input
              className="w3-input"
              type="text"
              placeholder={t("refer-input")}
              id="friend"
            />
            <a target="_blank" id="sendbutton" className="actiebutton">
              {t("refer-invite")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Action);
