import React from "react";
import ReactDOM from "react-dom";
import styles from "../styles/main.css";
import background from "./ER_Agents/imgs/start.jpg";
import overlay from "../imgs/logo.png";
import { withTranslation, useTranslation } from "react-i18next";

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
  }
  start() {
    localStorage.setItem("festiviti_start_time", new Date().getTime());
    localStorage.setItem("festiviti_extra_time", 0);
    localStorage.setItem("festiviti_punish_time", 0);
    window.location.href = this.props.next;
  }
  render() {
    const { t } = this.props;
    return (
      <div
        id="window"
        className="start_screen"
        style={{ backgroundImage: "url(" + this.props.background + ")"}}
      >
        <div className="centerbuttons">
          {this.props.escape ? (
            <p style={{ marginBottom: 0 }}>
              {t("test-escape")}
            </p>
          ) : (
            <p style={{ marginBottom: 0 }}>
              {t("test-game")}
            </p>
          )}

          {this.props.settings && (
            <a
              className="linkbutton"
              style={{ marginBottom: 10 }}
              onClick={() => (window.location.href = "testsettings")}
            >
              {t("settings")}
            </a>
          )}
          <a className="linkbutton" onClick={this.start}>
            {this.props.escape ? t("test-escape-button") : t("test-game-button")}
          </a>
        </div>
        <img className="overlay" src={overlay} />
      </div>
    );
  }
}

export default  withTranslation()(Test);
