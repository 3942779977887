import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import Gifts from "../../games/CHRSPECIAL/gifts.js";
import Settings from "../../games/CHRSPECIAL/settings.js";

class CHRSPECIAL extends React.Component {
  componentDidMount() {
    document.title = "Kerst Mysterie - Festiviti";
  }
  render() {
    if (this.props.match.params.phase == "play")
      return <Start
      background={backgroundIMG}
      next="gifts"
      escape={false}
      settings={true}
       />;
    if (this.props.match.params.phase == "test")
      return <Test
      background={backgroundIMG}
      next="testgifts"
      escape={false}
      settings={true}
       />;
    if (this.props.match.params.phase == "testgifts")
      return <Gifts back="test"/>;
      if (this.props.match.params.phase == "gifts")
        return <Gifts back="play"/>;
      if (this.props.match.params.phase == "testsettings")
        return <Settings back="test"/>;
      if (this.props.match.params.phase == "startsettings")
        return <Settings back="play"/>;
    else return <Redirect to="/overview" />;
  }
}

export default withRouter(CHRSPECIAL);
