import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import initTime from "../time.js";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import Access from "../../games/ER_Astro/access.js";
import Movie from "../../games/ER_Astro/movie.js";
import Phone from "../../games/ER_Astro/phone.js";
import Maze from "../../games/ER_Astro/maze.js";
import Engine from "../../games/ER_Astro/engine.js";
import Doors from "../../games/ER_Astro/doors.js";
import End from "../../games/end.js";

class ER_Astro extends React.Component {
  componentDidMount() {
    document.title = "Festiviti Escape Room";
    initTime();
  }
  render() {
    if (this.props.match.params.phase == "play")
      return <Start background={backgroundIMG} escape={true} next="movie" />;
    if (this.props.match.params.phase == "test")
      return <Test background={backgroundIMG} escape={true} next="movie" />;
    if (this.props.match.params.phase == "movie") return <Movie />;
    if (this.props.match.params.phase == "access") return <Access />;
    if (this.props.match.params.phase == "phone") return <Phone />;
    if (this.props.match.params.phase == "maze") return <Maze />;
    if (this.props.match.params.phase == "engine") return <Engine />;
    if (this.props.match.params.phase == "doors") return <Doors />;
    if (this.props.match.params.phase == "end") return <End />;
    else return <Redirect to="/" />;
  }
}

export default withRouter(ER_Astro);
