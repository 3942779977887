import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import initTime from "../time.js";
import { Redirect } from "react-router-dom";
import backgroundIMG from "./imgs/background_start.jpg";
//game screens
import Test from "../../games/test.js";
import Start from "../../games/start.js";
import End from "../../games/end.js";
import Wires from "../../games/ER_Labo/wires.js";
import CodePad from "../../games/ER_Labo/labo_codepad.js";
import Menu from "../../games/ER_Labo/menu.js";
import Recept from "../../games/ER_Labo/recept.js";

class ER_Labo extends React.Component {
  componentDidMount() {
    document.title = "Festiviti Escape Room";
    initTime();
  }
  render() {
    if (this.props.match.params.phase == "play")
      return <Start background={backgroundIMG} escape={true} next="wires" />;
    if (this.props.match.params.phase == "test")
      return <Test background={backgroundIMG} escape={true} next="wires" />;
    if (this.props.match.params.phase == "wires") return <Wires />;
    if (this.props.match.params.phase == "codepad") return <CodePad />;
    if (this.props.match.params.phase == "menu") return <Menu />;
    if (this.props.match.params.phase == "recept") return <Recept />;
    if (this.props.match.params.phase == "end") return <End />;
    else return <Redirect to="/" />;
  }
}

export default withRouter(ER_Labo);
