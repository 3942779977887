import React from "react";
import ReactDOM from "react-dom";
import styles from "../ER_Boyard/access.css";
import service from "../ER_Boyard/imgs/login_back.jpg";
import blogo from "../ER_Boyard/imgs/logo.png";
import { withTranslation } from "react-i18next";

class Access extends React.Component {
  constructor(props) {
    super(props);}
  check() {
    if (document.getElementById("wachtwoord").value == "697118") {
      document.getElementById("wachtwoord").value = "";
      localStorage.setItem("festiviti_start_time", new Date().getTime());
      localStorage.setItem("festiviti_extra_time", 0);
      localStorage.setItem("festiviti_punish_time", 0);
      window.location.href = "helicopter";
    }
  }
  render() {
    const {t}=this.props;
    return (
      <div className="Boyard_Access">
        <div className="demo">
          <div className="login">
            <img src={blogo} className="logo" />
            <div className="login__check"></div>
            <div className="login__form">
              <p id="error_message">{t("robin-wrong")}</p>

              <div className="login__row">
                <input
                  type="password"
                  id="wachtwoord"
                  className="login__input name"
                  placeholder={t("robin-code")}
                />
                <button
                  onClick={() => this.check()}
                  type="button"
                  className="loginsubmit"
                >
                  {t("robin-login")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(['games'])(Access);
