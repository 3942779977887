import $ from "jquery";

var animating = false,
  submitPhase1 = 1100,
  submitPhase2 = 400,
  logoutPhase1 = 800,
  $login = $(".login"),
  $app = $(".app");

function ripple(elem, e) {
  $(".ripple").remove();
  var elTop = elem.offset().top,
    elLeft = elem.offset().left,
    x = e.pageX - elLeft,
    y = e.pageY - elTop;
  var $ripple = $("<div class='ripple'></div>");
  $ripple.css({ top: y, left: x });
  elem.append($ripple);
}
function animation(that, e) {
  if (animating) return;
  animating = true;
  ripple($(that), e);
  $(that).addClass("processing");
}
function spinner(e) {
  console.log("test");
  animation($(".login__submit"), e);
}
function remove_spinner() {
  $(".ripple").remove();
  $(".login__submit").removeClass("processing");
  animating = false;
}
function done() {
  $(".login__submit").addClass("success");
  setTimeout(function () {
    $app.show();
    $app.css("top");
    $app.addClass("active");
  }, submitPhase2 - 70);
  setTimeout(function () {
    window.location.href = "";
  }, submitPhase2);
}

export { remove_spinner, spinner, done };
