import logo from './logo.svg';
import './App.css';
import Login from './Components/Login'
import Admin from './Components/Admin'
import LoginSchool from './Components/LoginSchool'
import Game from './Components/Game'
import Homepage from './Components/Homepage'
import GroupOverview from './Components/HomepageGroups'
import React, { useState, Suspense } from "react";
import i18n from "./i18n";
import { Spin, Space } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

const spinner=()=>{
return(
   <div style={{ height:"100%",width:"100%", display: 'inline-flex', justifyContent: 'center'}}>
<Spin size="large" style={{ margin:"20%"}}/>
 </div>
)
}

function refreshUser(prev_user){
  return fetch(process.env.REACT_APP_BACKEND+"/api/refresh", {method:'GET',
  headers: {'Authorization': 'Bearer ' + prev_user.token}})
  .then(response => response.json()).then(json => {localStorage.setItem('user', JSON.stringify(json));return json;}).catch(error => null);
}
function App() {
  const [online, setOnline] = useState(true);
  const [user, setUser] = useState(null);
  if (process.env.NODE_ENV == "development") {
    console.log("development!");
  require('dotenv').config();
  }
  console.log("backend: "+process.env.REACT_APP_BACKEND);
  const prev_user=JSON.parse(localStorage.getItem('user'));
  fetch(process.env.REACT_APP_BACKEND+"/api/ping").catch((error) => {setOnline(false);});
  if (prev_user &&! user){
    refreshUser(prev_user).then(u=>setUser(u));
  }
      if (online)
  return (
    <div translate="no" id="appRoot" style={{whiteSpace: "pre-line", height:"100%",width:"100%"}}>
     <Suspense fallback={spinner()}>
       <Router>
     <Switch>
         <Route path="/group">
          {user?<GroupOverview/>: <LoginSchool/>}
          </Route>
          <Route path="/game/:sku/:phase">
           {(user || prev_user)? <Game/>: <Redirect to="/"/>}
          </Route>
          <Route path="/admin">
           <Admin/>
          </Route>
          <Route path="/">
          {user? <Homepage/>: <Login/>}
          </Route>
      </Switch>
        </Router>
     </Suspense>
     </div>
  );
  else return ( <h1>Backend Offline</h1>)
}
export default App;
