import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Input, Button, Modal } from "antd";
import style from "../CHRSPECIAL/gifts.css";
import g1 from "../CHRSPECIAL/imgs/g1.png";
import g2 from "../CHRSPECIAL/imgs/g2.png";
import g3 from "../CHRSPECIAL/imgs/g3.png";
import g4 from "../CHRSPECIAL/imgs/g4.png";
import g5 from "../CHRSPECIAL/imgs/g5.png";
import gg2 from "../CHRSPECIAL/imgs/g2-grey.png";
import gg3 from "../CHRSPECIAL/imgs/g3-grey.png";
import gg4 from "../CHRSPECIAL/imgs/g4-grey.png";
import gg5 from "../CHRSPECIAL/imgs/g5-grey.png";
import arrow from "../CHRSPECIAL/imgs/arrow.png";
import top from "../CHRSPECIAL/imgs/top.png";
import bottom from "../CHRSPECIAL/imgs/bottom.png";

class Gifts extends React.Component {
  constructor(props) {
    super(props);
    this.showGiftHint = this.showGiftHint.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      start:true,
      answer:"",
      showHint:true,
      showAnswer: false,
      hints: ["Heb je zeker je hints goed ingesteld?","Heb je zeker je hints goed ingesteld?","Heb je zeker je hints goed ingesteld?","Heb je zeker je hints goed ingesteld?","Heb je zeker je hints goed ingesteld?"],
      counter:0,
    };
  }
  showGiftHint(e,id){
    e.stopPropagation();
    if(this.state.counter==id)
    this.setState({showHint:true})

  }
  submit(e){
    const answers=["RENDIER","DENNENBOMEN","MUTS","POPPEN"];
    e.stopPropagation();
    if (this.state.answer.toUpperCase()===answers[this.state.counter]){
      this.setState({counter:this.state.counter+1,showHint:false,showAnswer:false,
        answer:""})
    }
  }
  componentDidMount() {
    console.log("mount");
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(info);
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      { method: "GET", headers: { Authorization: "Bearer " + user.token } }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.hints)
      this.setState({
        hints: result.hints,
      });
      });
    }
  render() {
    return (
      <div className="CHRISTMAS" onClick={()=>{console.log("hide");this.setState({showHint:false,showAnswer:false, start:false})}}>
      <img id="cross" src={arrow} onClick={()=>window.location.href =this.props.back}/>
      <img id="top" src={top}/>
       <img id="bottom" src={bottom}/>
      <div id="gifts">
       <img id="gift1" className={this.state.counter==0? "gift movinggift":"gift"}
       src={g1} onClick={(e)=>this.showGiftHint(e,0)}/>
          <img id="gift2" className={this.state.counter==1? "gift movinggift":"gift"}
          src={this.state.counter>0? g2:gg2} onClick={(e)=>this.showGiftHint(e,1)}/>
          <img id="gift3" className={this.state.counter==2? "gift movinggift":"gift"}
          src={this.state.counter>1? g3:gg3} onClick={(e)=>this.showGiftHint(e,2)}/>
          <img id="gift4" className={this.state.counter==3? "gift movinggift":"gift"}
          src={this.state.counter>2? g4:gg4} onClick={(e)=>this.showGiftHint(e,3)}/>
          <img id="gift5" className={this.state.counter==4? "gift movinggift":"gift"}
          src={this.state.counter>3? g5:gg5} onClick={(e)=>this.showGiftHint(e,4)}/>
          </div>
      <div id="hint" style={{display:this.state.showHint?"block":"none"}} onClick={(e)=>{e.stopPropagation();if(this.state.counter<4 && !this.state.start)this.setState({showAnswer:true,showHint:false})}}>
      <div id="hinttext">{this.state.start?<span>Oh nee, de kerstman heeft het dit jaar wat moeilijker gemaakt...<br/>Los jij het grote kerst-mysterie op?</span>:this.state.hints[this.state.counter]}</div>
      </div>
      <div id="answer" style={{display:this.state.showAnswer?"block":"none"}} onClick={(e)=>e.stopPropagation()}>
          <div id="back-button" onClick={(e)=>{e.stopPropagation();this.setState({showAnswer:false,showHint:true})}}>Bekijk het raadsel</div>
          <div id="fillin">
          Los jij het mysterie op?<br/>
              <input value={this.state.answer} onChange={(event)=>this.setState({answer:event.target.value})} id="password" type="text" size="13"/>
              <p id="wrong"></p>
              <div id="submit" onClick={this.submit}><img id="bannergift" src={g1}/></div>
          </div>
          </div>
      </div>

    )};
  }
export default Gifts;
