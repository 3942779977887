import React, { Component } from "react";
import key from "../imgs/key.svg";
import mail from "../imgs/mail.svg";
import logo from "../imgs/logo.png";
import background from "../imgs/background.jpg";
import styles from "../styles/login.css";
import { Button,Table, Space,Spin,PageHeader, Descriptions } from "antd";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordernumber: "",
      email: "",
      games: null,
      loading: false,
    };
    this.searchGames();
  }
  searchGames() {
    const email = encodeURIComponent(this.state.email);
    const ordernumber = encodeURIComponent(this.state.ordernumber);
    var searchstring = "";
    if (ordernumber.length > 0) {
      searchstring = "?ordernumber=" + ordernumber;
    } else if (email.length > 0) {
      searchstring = "?email=" + email;
    }
    if (searchstring.length > 0) {
      console.log(
        process.env.REACT_APP_BACKEND + "/api/admin_search" + searchstring
      );
      fetch(
        process.env.REACT_APP_BACKEND + "/api/admin_search" + searchstring,
        { method: "GET", headers: { APIKEY: "SECRET" } }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.games) {
            console.log(res.games);
            this.setState({
              loading: false,
              games: res.games,
              email: res.user.email,
              userid: res.user.id,
            });
          } else {
            this.setState({ loading: false });
          }
        });
    }
  }
  resetGame(index) {
    var games=this.state.games;
    var game=games[index];
    games[index].resetting=true;
    this.setState({games:games})
    const searchstring =
      "?order=" + game.order + "&sku=" + game.sku + "&user=" + this.state.userid;
    fetch(process.env.REACT_APP_BACKEND + "/api/admin_reset" + searchstring, {
      method: "GET",
      headers: { APIKEY: "SECRET" },
    })
      .then((response) => response.json())
      .then((r) => {
        console.log(r);
        games[index].resetting=false;
        this.setState({games:games})
        //searchGames()
      });
  }
  sendEmail(index) {
    var games=this.state.games;
    var game=games[index];
    games[index].sending=true;
    this.setState({games:games})
    console.log(game);
    const body={
    email: this.state.email,
    financial_status:"paid",
    order_number:game.order,
    "fulfillment_status":"no",
    customer: {
        id: this.state.userid
        }
      }
      console.log(JSON.stringify(body));
      console.log(process.env.REACT_APP_BACKEND + "/api/new_order?force=true");
    fetch(process.env.REACT_APP_BACKEND + "/api/new_order?force=true", {
      method: "POST",
      headers: { APIKEY: "SECRET" ,  "Content-Type": "application/json",},
      body: JSON.stringify(body)
    })
      .then((response) =>   console.log(response))
      .then((r) => {
        console.log(r);
        games[index].sending=false;
        this.setState({games:games})
      });
  }
  handleClick(event) {
    this.searchGames();
  }
  handleChange(event, key) {
    if (key == "ordernumber")
      this.setState({ ordernumber: event.target.value });
    if (key == "email") this.setState({ email: event.target.value });
  }

  render() {
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'order',
        key: 'order',
      },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
  },

  {
    title: 'Order Date',
    dataIndex: 'order_date',
    key: 'order_date',
  },
  {
    title: 'Activation Time',
    dataIndex: 'activationTime',
    key: 'activationTime',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record,index) => (
      <Space size="middle">
      <span>
        {record.resetting?<Spin />:
        <a disabled={!record.activationTime} onClick={()=>this.resetGame(index)}>Reset Access</a>}
        </span>
        <span>
        {record.sending?<Spin />:
        <a onClick={()=>this.sendEmail(index)}>Send Email</a>

      }
      </span>
      </Space>
    ),
  },
];
    return this.state.userid ? (
      <div>
      <PageHeader
      ghost={false}
      onBack={() => this.setState({userid:null, email:"",ordernumber:""})}
      title="User Overview"
      subTitle="All orders"
      extra={[
        <Button key="1" type="primary" onClick={()=>this.searchGames()}>
          refresh
        </Button>,
      ]}
    >
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Email">{this.state.email}</Descriptions.Item>
        <Descriptions.Item label="User Id">
        {this.state.userid}</Descriptions.Item>
      </Descriptions>
    </PageHeader>
      <Table dataSource={this.state.games} columns={columns}   pagination={false}/>
      </div>

    ) : (
      <div
        className="cont"
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        <div
          className="demo"
          style={{ height: 250, width: 300, "margin-left": -150 }}
        >
          <div className="login" style={{ height: 250, width: 300 }}>
            <div className="login__check"></div>
            <div className="login__form">
              <div className="login__row">
                <input
                  type="text"
                  id="number"
                  className="login__input"
                  placeholder="ordernummmer"
                  value={this.state.ordernumber}
                  onChange={(e) => {
                    this.handleChange(e, "ordernumber");
                  }}
                />
              </div>

              <div className="login__row">
                <input
                  type="text"
                  id="email"
                  className="login__input"
                  placeholder="email"
                  value={this.state.email}
                  onChange={(e) => {
                    this.handleChange(e, "email");
                  }}
                />
              </div>
              <Button
                type="primary"
                style={{
                  margin: 20,
                  background: "#F0730D",
                  borderColor: "#F0730D",
                }}
                loading={this.state.loading}
                onClick={(event) => {
                  this.setState({ loading: true });
                  this.handleClick(event);
                }}
              >
                ZOEKEN
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
