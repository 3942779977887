import React from "react";
import ReactDOM from "react-dom";
import fingerprint from "../ER_Agents/imgs/Fingerprint.png";
import alarm from "../ER_Agents/audio/alarm.mp3";
import styles from "../ER_Agents/alarm.css";
import { withTranslation } from "react-i18next";

class Alarm extends React.Component {
  constructor(props) {
    super(props);}
  check() {
    if (
      document.getElementById("1").checked &&
      document.getElementById("2").checked &&
      !document.getElementById("3").checked &&
      !document.getElementById("4").checked &&
      document.getElementById("5").checked &&
      !document.getElementById("6").checked &&
      document.getElementById("7").checked
    ) {
      window.location.href = "secret";
    }
  }
  start(e) {
    var audio = document.getElementById("alarm");
    audio.play();
    document.getElementById("overlay").style.visibility = "hidden";
  }
  render() {
    const {t}=this.props;
    return (
      <div id="window" className="Agents_Alarm">
        <div onClick={this.start} id="overlay">
          <img id="finger" src={fingerprint} />
        </div>
        <p id="time"></p>
        <p id="info">
          {t("agent-alarm")}
        </p>
        <span className="toggle">
          <input onClick={this.check} id="1" type="checkbox" />
          <label
            className="tlabel"
            data-off="&#10006;"
            data-on="&#10004;"
          ></label>
        </span>

        <span className="toggle">
          <input onClick={this.check} id="2" type="checkbox" />
          <label data-off="&#9724;" data-on="&#9654;"></label>
        </span>

        <span className="toggle">
          <input onClick={this.check} id="3" type="checkbox" />
          <label id="b1" data-off={t("agent-yellow")} data-on={t("agent-blue")}></label>
        </span>
        <span className="toggle">
          <input onClick={this.check} id="4" type="checkbox" />
          <label id="b2" data-off={t("agent-stop")} data-on={t("agent-start")}></label>
        </span>
        <span className="toggle">
          <input onClick={this.check} id="5" type="checkbox" />
          <label id="b3" data-off={t("agent-red")} data-on={t("agent-green")}></label>
        </span>
        <span className="toggle">
          <input onClick={this.check} id="6" type="checkbox" />
          <label id="b4" data-off={t("agent-on")} data-on={t("agent-off")}></label>
        </span>
        <span className="toggle">
          <input onClick={this.check} id="7" type="checkbox" />
          <label data-off="1" data-on="2"></label>
        </span>
        <p id="serial">{t("agent-number")} 56098712</p>
        <p id="type">{t("agent-type")} B17</p>
        <audio src={alarm} id="alarm" loop></audio>
      </div>
    );
  }
}

export default withTranslation(['games'])(Alarm);
