import React, { useEffect } from "react";
import ReactDOM from "react-dom";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/welcome.css";
import loading from "../FB_Hero/imgs/loading.gif";
import alert from "../FB_Hero/imgs/alert.png";
import mission from "../FB_Hero/audio/mission_superhelden.mp3";

class Welcome extends React.Component {
  componentDidMount() {
    var element = document.getElementById("title");
    setTimeout(function () {
      element.innerHTML = "Toegang krijgen tot beveiligd systeem...";
    }, 2000);
    setTimeout(function () {
      element.innerHTML = "Inloggen in database...";
    }, 5000);
    setTimeout(function () {
      element.innerHTML = "Controleren op recente misdaden...";
    }, 8000);
    setTimeout(function () {
      document.getElementById("form").style.display = "block";
    }, 11000);
  }
  render() {
    return (
      <div className="Hero_Welcome" style={{ width: "100%", height: "100%" }}>
        <audio src={mission} id="mission" />
        <div className="cont">
          <div className="demo">
            <h1 id="title">Account aanmaken voor hoodkwartier...</h1>
            <img className="loading" src={loading} />
          </div>
        </div>

        <div id="form" className="modal">
          <form className="modal-content animate" method="post">
            <div className="imgcontainer">
              <span className="close" title="Close Modal">
                &times;
              </span>
              <img src={alert} alt="Avatar" className="avatar" />
            </div>

            <div className="container">
              <p>
                <b>Inbraak in de snoepfabriek</b>
                <br />
                Tijdstip : 3 uur geleden.
                <br />
              </p>
              <button
                type="button"
                className="briefing"
                onClick={() => document.getElementById("mission").play()}
              >
                Beluister Briefing
              </button>
              <button
                type="button"
                onClick={() => (window.location.href = "witness")}
                className="getuigen_knop"
              >
                Contacteer Getuigen
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Welcome;
