import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Input } from "antd";
//import javascript from '../ER_Labo/codepad.jsx'
import styles from "../FB_Hero/panel.css";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
//import loading from "../FB_Hero/imgs/loading2.gif";

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: { lat: -25, lng: 131 },
      lat: "",
      lng: "",
    };
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(info);
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      { method: "GET", headers: { Authorization: "Bearer " + user.token } }
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.lat) result = { lat: 52.3727598, lng: 4.8936041 };
        this.setState({
          location: result,
          lat: result.lat.toString(),
          lng: result.lng.toString(),
        });
      })
      .catch(() => {
        const result = { lat: 52.3727598, lng: 4.8936041 };
        this.setState({
          location: result,
          lat: result.lat.toString(),
          lng: result.lng.toString(),
        });
      });
  }
  save() {
    const info = JSON.parse(localStorage.getItem("game_info"));
    const user = JSON.parse(localStorage.getItem("user"));
    const location = { lat: this.state.lat, lng: this.state.lng };
    console.log(JSON.stringify(location));
    fetch(
      process.env.REACT_APP_BACKEND +
        "/api/gamesettings?game=" +
        info["game"] +
        "&order=" +
        info["order"],
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(location),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ location: location });
        console.log(result);
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div className="HEROsettings" style={{ width: "100%", height: "100%" }}>
        <div id="text">
          <h1>Locatie van de buit</h1>
          <p>
            <b>STAP 1:</b> Wanneer je een leuke plek hebt ontdekt om de buit te
            verstoppen, zoek je de exacte coordinaten op via{" "}
            <a
              style={{ color: "#ff8400" }}
              href="https://www.gps-coordinaten.nl"
              target="_blank"
            >
              deze link.
            </a>
            <br />
            <br />
            <b>STAP 2:</b> Kopieer de coordinaten naar dit scherm, en klik op
            "opslaan". De locatie van de buit is hier vervolgens zichtbaar.
            Klaar om het spel te spelen!
          </p>
          <Input
            placeholder="Breedtegraad"
            style={{ margin: 10 }}
            value={this.state.lat}
            onChange={(e) => this.setState({ lat: e.target.value })}
          />
          <Input
            placeholder="Lengtegraad"
            style={{ margin: 10 }}
            value={this.state.lng}
            onChange={(e) => this.setState({ lng: e.target.value })}
          />
          <a
            href="#"
            className="button"
            onClick={this.save}
            style={{ margin: 10 }}
          >
            OPSLAAN
          </a>
          <a
            href={this.props.back}
            className="button"
            onclick="exit()"
            style={{ margin: 10 }}
          >
            TERUG
          </a>
        </div>
        <Map
          id="map"
          google={this.props.google}
          zoom={17}
          style={{ width: "70%", height: 300, marginLeft: "15%" }}
          defaultCenter={this.state.location}
          center={this.state.location}
        >
          <Marker position={this.state.location} text=""></Marker>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API,
})(Settings);
